export interface DataPoint {
  datapoint: string;
  id: string;
  name: number;
  position: number[];
  regionRef: string;
  propertyRef: string;
  highresImageUrl: string | null;
  lowResArrayBuffer: ArrayBuffer | null;
  depthmapArrayBuffer: ArrayBuffer | null;
  frontImageUrl: string | undefined;
  rightImageUrl: string | undefined;
  leftImageUrl: string | undefined;
  topImageUrl: string | undefined;
  bottomImageUrl: string | undefined;
  backImageUrl: string | undefined;
}

export interface Region {
  id: string;
  label: string;
  position: [number, number, number];
  propertyRef: string;
  regionCoordinates: [number, number][];
  datapoints: DataPoint[];
}

export interface Property {
  id: string;
  title: string;
  regions: Region[];
}

export enum StreamingDataType {
  HIGH_RESOLUTION = 'high_resolution',
  LOW_RESOLUTION = 'low_resolution',
  DEPTH_MAP_RESOLUTION = 'depth_map_resolution',
}

export type StreamingDataConfigType = {
  datapointId: string;
  arrayBuffer: ArrayBuffer | null;
  url: string;
  type: StreamingDataType;
};

export type SyncStreamingDataResponse = {
  streamingQueue: StreamingDataConfigType[]
};


export type StreamSegmentConfigType = {
  columnNumber: number;
  rowNumber: number;
  imageBuffer: ArrayBuffer;
};
