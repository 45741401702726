import { useRef } from "react";
import { Canvas } from "@react-three/fiber";
import { XR, Controllers, Hands, VRButton } from "@react-three/xr";
import { OrbitControls } from "@react-three/drei";
import ElevenKImageModel from "components/ElevenKImageModel";

export default function WebpElevenKModel() {
  const sphereRef = useRef();

  return (
    <>
      <VRButton />
      <Canvas>
        <XR referenceSpace="local-floor">
          <ambientLight intensity={0.9} />
          <ElevenKImageModel ref={sphereRef} />
          <Controllers />
          <Hands />
          <OrbitControls />
        </XR>
      </Canvas>
    </>
  );
}
