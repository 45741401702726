import { useRef, useEffect } from "react";
import { useFrame } from "@react-three/fiber";
import { useXR } from "@react-three/xr";
import * as THREE from "three";
import usePlayerWithDev from "hooks/usePlayerWithDev";

interface UseSceneLogicParams {
  globeRef: React.MutableRefObject<THREE.Mesh | undefined>;
}

export default function useSceneLogic({ globeRef }: UseSceneLogicParams) {
  const { session, referenceSpace, isPresenting } = useXR();
  const { player } = usePlayerWithDev();

  const xrRefSpace = useRef<XRReferenceSpace | null>(null);
  const devPlayerRef = useRef<THREE.Mesh>();
  const openingRaycasterRef = useRef(new THREE.Raycaster());
  const intersectedGlobe =
    useRef<THREE.Intersection<THREE.Object3D<THREE.Object3DEventMap>>>();

  /**
   * 1) Request and cache XR reference space once.
   */
  useEffect(() => {
    if (session && referenceSpace) {
      session
        .requestReferenceSpace(referenceSpace)
        .then((refSpace: XRReferenceSpace) => {
          xrRefSpace.current = refSpace;
        });
    }
  }, [session, referenceSpace]);

  /**
   * 2) Raycasting the Globe and Openings
   *    Different logic for VR (isPresenting) vs Dev mode
   */
  useFrame(() => {
    if (!globeRef.current) return;

    if (isPresenting && session && xrRefSpace.current) {
      session.requestAnimationFrame((timestamp, xrFrame) => {
        const pose = xrFrame.getViewerPose(xrRefSpace.current!);
        if (!pose) return;

        // Compute XR camera direction.
        const orientation = pose.transform.orientation;
        const quaternion = new THREE.Quaternion(
          orientation.x,
          orientation.y,
          orientation.z,
          orientation.w
        );
        const direction = new THREE.Vector3(0, 0, -1)
          .applyQuaternion(quaternion)
          .normalize();

        // Cast a ray toward the globe.
        openingRaycasterRef.current.set(player.position, direction);

        // Intersect with the globe.
        const hit = openingRaycasterRef.current.intersectObjects([
          globeRef.current,
        ]);
        if (hit.length > 0) {
          intersectedGlobe.current = hit[0];
        }
      });
    }
  });

  /**
   * 3) Sync Globe’s position with player.
   *    Removes warping/stretching artifacts.
   */
  useFrame(() => {
    if (globeRef.current) {
      globeRef.current.position.copy(player.position);
    }
  });

  return {
    devPlayerRef,
    intersectedGlobe,
  };
}
