import { useRef, useState, useEffect } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { useXR } from "@react-three/xr";
import * as THREE from "three";
import useMovementHelper from "hooks/useMovementHelper";
import usePlayerWithDev from "hooks/usePlayerWithDev";
import { DataPointType, Config } from "utils/types";

interface UseSceneLogicParams {
  propertyConfig: Config[];
  dataPointList: DataPointType[];
  openingList: any[];
  currentDatapointIndex: number;
  setCurrentDatapointIndex: (index: number) => void;
  globeRef: React.MutableRefObject<THREE.Mesh | undefined>;
}

/**
 * Replicates the logic from Scene.tsx:
 *  - Raycasting floors/rooms
 *  - Raycasting openings (with XR or dev camera)
 *  - Keeping the globe’s position in sync with the player
 *  - Filtering data points
 *  - Handling BoxHelper logic
 */
export default function useSceneLogic({
  propertyConfig,
  dataPointList,
  openingList,
  currentDatapointIndex,
  setCurrentDatapointIndex,
  globeRef,
}: UseSceneLogicParams) {
  const { checkIfPlayerIsCloseEnoughToDataPointe, checkIsDataPointViewable } =
    useMovementHelper();
  const { session, referenceSpace, isPresenting } = useXR();
  const { scene } = useThree();
  const { player } = usePlayerWithDev();

  const xrRefSpace = useRef<XRReferenceSpace | null>(null);
  const devPlayerRef = useRef<THREE.Mesh>();
  const roomRefs = useRef<THREE.Mesh[]>([]);
  const openingRefs = useRef<THREE.Mesh[]>([]);
  const roomRaycasterRef = useRef(new THREE.Raycaster());
  const openingRaycasterRef = useRef(new THREE.Raycaster());
  const roomIntersectionArrowRef = useRef<THREE.ArrowHelper>(null!);

  const [filteredDataPoints, setFilteredDataPoints] = useState<DataPointType[]>([]);

  const intersectedRoom = useRef<THREE.Intersection<THREE.Object3D<THREE.Object3DEventMap>>>();
  const intersectedOpenings = useRef<THREE.Intersection<THREE.Object3D<THREE.Object3DEventMap>>[]>();
  const intersectedGlobe = useRef<THREE.Intersection<THREE.Object3D<THREE.Object3DEventMap>>>();

  const boxRef = useRef<THREE.Group>();
  const boxHelperRef = useRef<THREE.BoxHelper>();

  const userGettingAwayRef = useRef(false);

  /**
   * Request and cache XR reference space once.
   */
  useEffect(() => {
    if (session && referenceSpace) {
      session.requestReferenceSpace(referenceSpace).then((refSpace: XRReferenceSpace) => {
        xrRefSpace.current = refSpace;
      });
    }
  }, [session, referenceSpace]);

  /**
   * 1) Floor (Room) Raycasting for intersection
   */
  useFrame(() => {
    if (!roomRefs.current.every((object) => object)) return;

    const rayOrigin = player.position;
    const rayDirection = new THREE.Vector3(0, -1, 0).normalize();
    roomRaycasterRef.current.set(rayOrigin, rayDirection);

    const intersectedObjects = roomRaycasterRef.current.intersectObjects(roomRefs.current);
    intersectedRoom.current = intersectedObjects[0];
  });

  /**
   * 2) Raycasting the Globe and Openings
   *    Different logic for VR (isPresenting) vs Dev mode
   */
  useFrame(() => {
    if (!globeRef.current) return;

    if (isPresenting && session && xrRefSpace.current) {
      session.requestAnimationFrame((timestamp, xrFrame) => {
        const pose = xrFrame.getViewerPose(xrRefSpace.current!);
        if (!pose) return;

        // Compute XR camera direction.
        const orientation = pose.transform.orientation;
        const quaternion = new THREE.Quaternion(
          orientation.x,
          orientation.y,
          orientation.z,
          orientation.w
        );
        const direction = new THREE.Vector3(0, 0, -1)
          .applyQuaternion(quaternion)
          .normalize();

        // Cast a ray toward the globe.
        openingRaycasterRef.current.set(player.position, direction);

        // Intersect with the globe.
        const hit = openingRaycasterRef.current.intersectObjects([globeRef.current]);
        if (hit.length > 0) {
          intersectedGlobe.current = hit[0];
        }
      });
    }
  });

  /**
   * 3) Raycasting for Openings in both XR and Dev modes.
   */
  useFrame(() => {
    if (isPresenting && session && xrRefSpace.current) {
      session.requestAnimationFrame((timestamp, xrFrame) => {
        const pose = xrFrame.getViewerPose(xrRefSpace.current!);
        if (!pose) return;

        const orientation = pose.transform.orientation;
        const quaternion = new THREE.Quaternion(
          orientation.x,
          orientation.y,
          orientation.z,
          orientation.w
        );
        const direction = new THREE.Vector3(0, 0, -1)
          .applyQuaternion(quaternion)
          .normalize();

        openingRaycasterRef.current.set(player.position, direction);

        if (openingRefs.current.every((object) => object)) {
          intersectedOpenings.current =
            openingRaycasterRef.current.intersectObjects(openingRefs.current);
        }
      });
    } else {
      // Dev mode.
      if (!roomIntersectionArrowRef.current || !devPlayerRef.current) return;

      const rayDirection = devPlayerRef.current.getWorldDirection(new THREE.Vector3());
      openingRaycasterRef.current.set(player.position, rayDirection);

      intersectedOpenings.current =
        openingRaycasterRef.current.intersectObjects(openingRefs.current);
    }
  });

  /**
   * 4) Sync Globe’s position with player.
   *    Removes warping/stretching artifacts.
   */
  useFrame(() => {
    if (globeRef.current) {
      globeRef.current.position.copy(player.position);
    }
  });

  /**
   * 5) Setup BoxHelper for debug once.
   */
  useEffect(() => {
    if (!boxRef.current) return;
    boxHelperRef.current = new THREE.BoxHelper(boxRef.current, 0xff0000);
    scene.add(boxHelperRef.current);

    return () => {
      if (boxHelperRef.current) {
        scene.remove(boxHelperRef.current);
      }
    };
  }, [scene]);

  let pointsWithinCircularRegion: DataPointType[] = [];
  /**
   * 6) Filter datapoints & check if user is "getting away".
   */
  useFrame(() => {
    if (!intersectedOpenings.current) return;
    if (!boxRef.current) return;

    if (!isPresenting) return;

    for (let i = 0; i < dataPointList.length; i++) {
      if (i === currentDatapointIndex) continue;
      const element = dataPointList[i];

      const { distance, isViewable } = checkIsDataPointViewable(element.position);
      if (isViewable) {
        pointsWithinCircularRegion.push({
          ...element,
          name: element.url.replace("/images/office/", "").toUpperCase(),
          distance,
        });
      }

      if (checkIfPlayerIsCloseEnoughToDataPointe(distance)) {
        setCurrentDatapointIndex(i);
      }
    }

    if (!intersectedRoom.current) {
      userGettingAwayRef.current = true;
      setFilteredDataPoints(dataPointList);
      if (boxHelperRef.current) {
        boxHelperRef.current.visible = true;
      }
      return;
    } else {
      userGettingAwayRef.current = false;
      if (boxHelperRef.current) {
        boxHelperRef.current.visible = false;
      }
    }

    const currentRoomRegionName = intersectedRoom.current.object.name;
    const hasOutsidePoints = pointsWithinCircularRegion.some(
      (dp) => dp.regionRef !== currentRoomRegionName
    );

    if (hasOutsidePoints && intersectedOpenings.current.length === 0) {
      setFilteredDataPoints(
        pointsWithinCircularRegion.filter(dp => dp.regionRef === currentRoomRegionName)
      );
    } else {
      setFilteredDataPoints(pointsWithinCircularRegion);
    }
    pointsWithinCircularRegion.length = 0;

    if (boxHelperRef.current) {
      boxHelperRef.current.update();
    }
  });

  return {
    devPlayerRef,
    roomRefs,
    openingRefs,
    roomIntersectionArrowRef,
    boxRef,
    userGettingAwayRef,
    filteredDataPoints,
    intersectedGlobe,
  };
}
