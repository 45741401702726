import { Link } from "react-router-dom";
import { cn } from "utils/helper";

export default function DevelopmentHome() {
  const links = [
    {
      label: "High Resolution Segment Grid Environment",
      link: "/high-resolution-segment-grid-environment",
      priority: 2,
    },
    {
      label: "Webp 3d Model",
      link: "/webp-3d-model",
      priority: 1,
    },
    {
      label: "Just high res",
      link: "/just-high-res",
      priority: 1,
    },
    {
      label: "Depth Map",
      link: "/depth-map",
      priority: 2,
    },
    {
      label: "Development",
      link: "/development",
      priority: 2,
    },
    {
      label: "Development 2",
      link: "/development2",
      priority: 2,
    },
    {
      label: "Development 4",
      link: "/development4",
      priority: 2,
    },
    {
      label: "Depth Map Street View",
      link: "/depthmap-street-view",
      priority: 2,
    },
    {
      label: "Office Experience",
      link: "/experience?type=office",
      priority: 2,
    },
    {
      label: "3D Basketball Court Experience",
      link: "/3d-model-environment?type=basketballCourt",
      priority: 2,
    },
    {
      label: "Stereoscopic View (Experimental)",
      link: "/stereoscopic-view",
      priority: 2,
    },
  ];

  links.sort((a, b) => a.priority - b.priority);

  return (
    <div className="w-screen py-20 bg-blue-950 flex flex-col justify-center items-center gap-12">
      <h1 className="text-5xl font-extrabold text-white mb-10">TrueVue Reality</h1>

      <div className="flex flex-wrap justify-center gap-4">
        {links.map(({ link, label, priority }, index) => (
          <>
            {index > 0 && links[index - 1].priority !== priority && (
              <div className="w-full border-t-2 border-teal-200 my-20"></div>
            )}
            <Link
              to={link}
              className={cn(
                "flex justify-center items-center px-4",
                "w-auto min-h-48 text-teal-200 font-bold text-2xl rounded-md",
                "border-2 border-teal-200",
                "hover:bg-teal-200 hover:text-blue-950"
              )}
            >
              <span>{label}</span>
            </Link>
          </>
        ))}
      </div>
    </div>
  );
}
