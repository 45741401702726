/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 public/models/11k-image-model.glb --transform 
Files: public/models/11k-image-model.glb [26.17MB] > /Users/hamari_m1/Projects/Freelance-Projects/Upwork/Arturo/truevue-frontend/11k-image-model-transformed.glb [92.55KB] (100%)
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'

export default function ElevenKImageModel(props) {
  const { nodes, materials } = useGLTF('/models/11k-image-model.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Sphere.geometry} material={materials['Material.001']} />
    </group>
  )
}

// useGLTF.preload('/models/11k-image-model.glb')
