import { useRef, useMemo } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { useXR, VRButton, XR } from "@react-three/xr";
import * as THREE from "three";
import { TextureLoader } from "three";
import { OrbitControls } from "@react-three/drei";
import useSceneLogic from "components/HighResolutionSegmentEnvironement/useSceneLogic";
import FPSCounter from "components/FPSCounter";

const imageList = [
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:0",
    name: "segment-0-0",
    imageSrc: "/images/segments/segment-0-0.webp",
    minU: 0,
    maxU: 0.083203125,
    minV: 0.8752604166666667,
    maxV: 1,
    columnNumber: 0,
    rowNumber: 0,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:5",
    name: "segment-0-1",
    imageSrc: "/images/segments/segment-0-1.webp",
    minU: 0.08333333333333333,
    maxU: 0.16653645833333333,
    minV: 0.8752604166666667,
    maxV: 1,
    columnNumber: 1,
    rowNumber: 0,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:6",
    name: "segment-0-2",
    imageSrc: "/images/segments/segment-0-2.webp",
    minU: 0.16666666666666666,
    maxU: 0.24986979166666667,
    minV: 0.8752604166666667,
    maxV: 1,
    columnNumber: 2,
    rowNumber: 0,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:7",
    name: "segment-0-3",
    imageSrc: "/images/segments/segment-0-3.webp",
    minU: 0.25,
    maxU: 0.333203125,
    minV: 0.8752604166666667,
    maxV: 1,
    columnNumber: 3,
    rowNumber: 0,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:8",
    name: "segment-0-4",
    imageSrc: "/images/segments/segment-0-4.webp",
    minU: 0.3333333333333333,
    maxU: 0.41653645833333336,
    minV: 0.8752604166666667,
    maxV: 1,
    columnNumber: 4,
    rowNumber: 0,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:9",
    name: "segment-0-5",
    imageSrc: "/images/segments/segment-0-5.webp",
    minU: 0.4166666666666667,
    maxU: 0.4998697916666667,
    minV: 0.8752604166666667,
    maxV: 1,
    columnNumber: 5,
    rowNumber: 0,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:10",
    name: "segment-0-6",
    imageSrc: "/images/segments/segment-0-6.webp",
    minU: 0.5,
    maxU: 0.583203125,
    minV: 0.8752604166666667,
    maxV: 1,
    columnNumber: 6,
    rowNumber: 0,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:11",
    name: "segment-0-7",
    imageSrc: "/images/segments/segment-0-7.webp",
    minU: 0.5833333333333334,
    maxU: 0.6665364583333333,
    minV: 0.8752604166666667,
    maxV: 1,
    columnNumber: 7,
    rowNumber: 0,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:12",
    name: "segment-0-8",
    imageSrc: "/images/segments/segment-0-8.webp",
    minU: 0.6666666666666666,
    maxU: 0.7498697916666667,
    minV: 0.8752604166666667,
    maxV: 1,
    columnNumber: 8,
    rowNumber: 0,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:13",
    name: "segment-0-9",
    imageSrc: "/images/segments/segment-0-9.webp",
    minU: 0.75,
    maxU: 0.833203125,
    minV: 0.8752604166666667,
    maxV: 1,
    columnNumber: 9,
    rowNumber: 0,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:14",
    name: "segment-0-10",
    imageSrc: "/images/segments/segment-0-10.webp",
    minU: 0.8333333333333334,
    maxU: 0.9165364583333333,
    minV: 0.8752604166666667,
    maxV: 1,
    columnNumber: 10,
    rowNumber: 0,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:15",
    name: "segment-0-11",
    imageSrc: "/images/segments/segment-0-11.webp",
    minU: 0.9166666666666666,
    maxU: 0.9998697916666667,
    minV: 0.8752604166666667,
    maxV: 1,
    columnNumber: 11,
    rowNumber: 0,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:16",
    name: "segment-1-0",
    imageSrc: "/images/segments/segment-1-0.webp",
    minU: 0,
    maxU: 0.083203125,
    minV: 0.7502604166666667,
    maxV: 0.875,
    columnNumber: 0,
    rowNumber: 1,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:17",
    name: "segment-1-1",
    imageSrc: "/images/segments/segment-1-1.webp",
    minU: 0.08333333333333333,
    maxU: 0.16653645833333333,
    minV: 0.7502604166666667,
    maxV: 0.875,
    columnNumber: 1,
    rowNumber: 1,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:18",
    name: "segment-1-2",
    imageSrc: "/images/segments/segment-1-2.webp",
    minU: 0.16666666666666666,
    maxU: 0.24986979166666667,
    minV: 0.7502604166666667,
    maxV: 0.875,
    columnNumber: 2,
    rowNumber: 1,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:19",
    name: "segment-1-3",
    imageSrc: "/images/segments/segment-1-3.webp",
    minU: 0.25,
    maxU: 0.333203125,
    minV: 0.7502604166666667,
    maxV: 0.875,
    columnNumber: 3,
    rowNumber: 1,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:20",
    name: "segment-1-4",
    imageSrc: "/images/segments/segment-1-4.webp",
    minU: 0.3333333333333333,
    maxU: 0.41653645833333336,
    minV: 0.7502604166666667,
    maxV: 0.875,
    columnNumber: 4,
    rowNumber: 1,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:21",
    name: "segment-1-5",
    imageSrc: "/images/segments/segment-1-5.webp",
    minU: 0.4166666666666667,
    maxU: 0.4998697916666667,
    minV: 0.7502604166666667,
    maxV: 0.875,
    columnNumber: 5,
    rowNumber: 1,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:22",
    name: "segment-1-6",
    imageSrc: "/images/segments/segment-1-6.webp",
    minU: 0.5,
    maxU: 0.583203125,
    minV: 0.7502604166666667,
    maxV: 0.875,
    columnNumber: 6,
    rowNumber: 1,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:23",
    name: "segment-1-7",
    imageSrc: "/images/segments/segment-1-7.webp",
    minU: 0.5833333333333334,
    maxU: 0.6665364583333333,
    minV: 0.7502604166666667,
    maxV: 0.875,
    columnNumber: 7,
    rowNumber: 1,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:24",
    name: "segment-1-8",
    imageSrc: "/images/segments/segment-1-8.webp",
    minU: 0.6666666666666666,
    maxU: 0.7498697916666667,
    minV: 0.7502604166666667,
    maxV: 0.875,
    columnNumber: 8,
    rowNumber: 1,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:25",
    name: "segment-1-9",
    imageSrc: "/images/segments/segment-1-9.webp",
    minU: 0.75,
    maxU: 0.833203125,
    minV: 0.7502604166666667,
    maxV: 0.875,
    columnNumber: 9,
    rowNumber: 1,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:26",
    name: "segment-1-10",
    imageSrc: "/images/segments/segment-1-10.webp",
    minU: 0.8333333333333334,
    maxU: 0.9165364583333333,
    minV: 0.7502604166666667,
    maxV: 0.875,
    columnNumber: 10,
    rowNumber: 1,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:27",
    name: "segment-1-11",
    imageSrc: "/images/segments/segment-1-11.webp",
    minU: 0.9166666666666666,
    maxU: 0.9998697916666667,
    minV: 0.7502604166666667,
    maxV: 0.875,
    columnNumber: 11,
    rowNumber: 1,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:28",
    name: "segment-2-0",
    imageSrc: "/images/segments/segment-2-0.webp",
    minU: 0,
    maxU: 0.083203125,
    minV: 0.6252604166666667,
    maxV: 0.75,
    columnNumber: 0,
    rowNumber: 2,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:29",
    name: "segment-2-1",
    imageSrc: "/images/segments/segment-2-1.webp",
    minU: 0.08333333333333333,
    maxU: 0.16653645833333333,
    minV: 0.6252604166666667,
    maxV: 0.75,
    columnNumber: 1,
    rowNumber: 2,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:30",
    name: "segment-2-2",
    imageSrc: "/images/segments/segment-2-2.webp",
    minU: 0.16666666666666666,
    maxU: 0.24986979166666667,
    minV: 0.6252604166666667,
    maxV: 0.75,
    columnNumber: 2,
    rowNumber: 2,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:31",
    name: "segment-2-3",
    imageSrc: "/images/segments/segment-2-3.webp",
    minU: 0.25,
    maxU: 0.333203125,
    minV: 0.6252604166666667,
    maxV: 0.75,
    columnNumber: 3,
    rowNumber: 2,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:32",
    name: "segment-2-4",
    imageSrc: "/images/segments/segment-2-4.webp",
    minU: 0.3333333333333333,
    maxU: 0.41653645833333336,
    minV: 0.6252604166666667,
    maxV: 0.75,
    columnNumber: 4,
    rowNumber: 2,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:33",
    name: "segment-2-5",
    imageSrc: "/images/segments/segment-2-5.webp",
    minU: 0.4166666666666667,
    maxU: 0.4998697916666667,
    minV: 0.6252604166666667,
    maxV: 0.75,
    columnNumber: 5,
    rowNumber: 2,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:34",
    name: "segment-2-6",
    imageSrc: "/images/segments/segment-2-6.webp",
    minU: 0.5,
    maxU: 0.583203125,
    minV: 0.6252604166666667,
    maxV: 0.75,
    columnNumber: 6,
    rowNumber: 2,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:35",
    name: "segment-2-7",
    imageSrc: "/images/segments/segment-2-7.webp",
    minU: 0.5833333333333334,
    maxU: 0.6665364583333333,
    minV: 0.6252604166666667,
    maxV: 0.75,
    columnNumber: 7,
    rowNumber: 2,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:36",
    name: "segment-2-8",
    imageSrc: "/images/segments/segment-2-8.webp",
    minU: 0.6666666666666666,
    maxU: 0.7498697916666667,
    minV: 0.6252604166666667,
    maxV: 0.75,
    columnNumber: 8,
    rowNumber: 2,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:37",
    name: "segment-2-9",
    imageSrc: "/images/segments/segment-2-9.webp",
    minU: 0.75,
    maxU: 0.833203125,
    minV: 0.6252604166666667,
    maxV: 0.75,
    columnNumber: 9,
    rowNumber: 2,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:38",
    name: "segment-2-10",
    imageSrc: "/images/segments/segment-2-10.webp",
    minU: 0.8333333333333334,
    maxU: 0.9165364583333333,
    minV: 0.6252604166666667,
    maxV: 0.75,
    columnNumber: 10,
    rowNumber: 2,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:39",
    name: "segment-2-11",
    imageSrc: "/images/segments/segment-2-11.webp",
    minU: 0.9166666666666666,
    maxU: 0.9998697916666667,
    minV: 0.6252604166666667,
    maxV: 0.75,
    columnNumber: 11,
    rowNumber: 2,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:40",
    name: "segment-3-0",
    imageSrc: "/images/segments/segment-3-0.webp",
    minU: 0,
    maxU: 0.083203125,
    minV: 0.5002604166666667,
    maxV: 0.625,
    columnNumber: 0,
    rowNumber: 3,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:41",
    name: "segment-3-1",
    imageSrc: "/images/segments/segment-3-1.webp",
    minU: 0.08333333333333333,
    maxU: 0.16653645833333333,
    minV: 0.5002604166666667,
    maxV: 0.625,
    columnNumber: 1,
    rowNumber: 3,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:42",
    name: "segment-3-2",
    imageSrc: "/images/segments/segment-3-2.webp",
    minU: 0.16666666666666666,
    maxU: 0.24986979166666667,
    minV: 0.5002604166666667,
    maxV: 0.625,
    columnNumber: 2,
    rowNumber: 3,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:43",
    name: "segment-3-3",
    imageSrc: "/images/segments/segment-3-3.webp",
    minU: 0.25,
    maxU: 0.333203125,
    minV: 0.5002604166666667,
    maxV: 0.625,
    columnNumber: 3,
    rowNumber: 3,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:44",
    name: "segment-3-4",
    imageSrc: "/images/segments/segment-3-4.webp",
    minU: 0.3333333333333333,
    maxU: 0.41653645833333336,
    minV: 0.5002604166666667,
    maxV: 0.625,
    columnNumber: 4,
    rowNumber: 3,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:45",
    name: "segment-3-5",
    imageSrc: "/images/segments/segment-3-5.webp",
    minU: 0.4166666666666667,
    maxU: 0.4998697916666667,
    minV: 0.5002604166666667,
    maxV: 0.625,
    columnNumber: 5,
    rowNumber: 3,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:46",
    name: "segment-3-6",
    imageSrc: "/images/segments/segment-3-6.webp",
    minU: 0.5,
    maxU: 0.583203125,
    minV: 0.5002604166666667,
    maxV: 0.625,
    columnNumber: 6,
    rowNumber: 3,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:47",
    name: "segment-3-7",
    imageSrc: "/images/segments/segment-3-7.webp",
    minU: 0.5833333333333334,
    maxU: 0.6665364583333333,
    minV: 0.5002604166666667,
    maxV: 0.625,
    columnNumber: 7,
    rowNumber: 3,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:48",
    name: "segment-3-8",
    imageSrc: "/images/segments/segment-3-8.webp",
    minU: 0.6666666666666666,
    maxU: 0.7498697916666667,
    minV: 0.5002604166666667,
    maxV: 0.625,
    columnNumber: 8,
    rowNumber: 3,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:49",
    name: "segment-3-9",
    imageSrc: "/images/segments/segment-3-9.webp",
    minU: 0.75,
    maxU: 0.833203125,
    minV: 0.5002604166666667,
    maxV: 0.625,
    columnNumber: 9,
    rowNumber: 3,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:50",
    name: "segment-3-10",
    imageSrc: "/images/segments/segment-3-10.webp",
    minU: 0.8333333333333334,
    maxU: 0.9165364583333333,
    minV: 0.5002604166666667,
    maxV: 0.625,
    columnNumber: 10,
    rowNumber: 3,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:51",
    name: "segment-3-11",
    imageSrc: "/images/segments/segment-3-11.webp",
    minU: 0.9166666666666666,
    maxU: 0.9998697916666667,
    minV: 0.5002604166666667,
    maxV: 0.625,
    columnNumber: 11,
    rowNumber: 3,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:52",
    name: "segment-4-0",
    imageSrc: "/images/segments/segment-4-0.webp",
    minU: 0,
    maxU: 0.083203125,
    minV: 0.37526041666666665,
    maxV: 0.5,
    columnNumber: 0,
    rowNumber: 4,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:53",
    name: "segment-4-1",
    imageSrc: "/images/segments/segment-4-1.webp",
    minU: 0.08333333333333333,
    maxU: 0.16653645833333333,
    minV: 0.37526041666666665,
    maxV: 0.5,
    columnNumber: 1,
    rowNumber: 4,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:54",
    name: "segment-4-2",
    imageSrc: "/images/segments/segment-4-2.webp",
    minU: 0.16666666666666666,
    maxU: 0.24986979166666667,
    minV: 0.37526041666666665,
    maxV: 0.5,
    columnNumber: 2,
    rowNumber: 4,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:55",
    name: "segment-4-3",
    imageSrc: "/images/segments/segment-4-3.webp",
    minU: 0.25,
    maxU: 0.333203125,
    minV: 0.37526041666666665,
    maxV: 0.5,
    columnNumber: 3,
    rowNumber: 4,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:56",
    name: "segment-4-4",
    imageSrc: "/images/segments/segment-4-4.webp",
    minU: 0.3333333333333333,
    maxU: 0.41653645833333336,
    minV: 0.37526041666666665,
    maxV: 0.5,
    columnNumber: 4,
    rowNumber: 4,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:57",
    name: "segment-4-5",
    imageSrc: "/images/segments/segment-4-5.webp",
    minU: 0.4166666666666667,
    maxU: 0.4998697916666667,
    minV: 0.37526041666666665,
    maxV: 0.5,
    columnNumber: 5,
    rowNumber: 4,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:58",
    name: "segment-4-6",
    imageSrc: "/images/segments/segment-4-6.webp",
    minU: 0.5,
    maxU: 0.583203125,
    minV: 0.37526041666666665,
    maxV: 0.5,
    columnNumber: 6,
    rowNumber: 4,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:59",
    name: "segment-4-7",
    imageSrc: "/images/segments/segment-4-7.webp",
    minU: 0.5833333333333334,
    maxU: 0.6665364583333333,
    minV: 0.37526041666666665,
    maxV: 0.5,
    columnNumber: 7,
    rowNumber: 4,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:60",
    name: "segment-4-8",
    imageSrc: "/images/segments/segment-4-8.webp",
    minU: 0.6666666666666666,
    maxU: 0.7498697916666667,
    minV: 0.37526041666666665,
    maxV: 0.5,
    columnNumber: 8,
    rowNumber: 4,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:61",
    name: "segment-4-9",
    imageSrc: "/images/segments/segment-4-9.webp",
    minU: 0.75,
    maxU: 0.833203125,
    minV: 0.37526041666666665,
    maxV: 0.5,
    columnNumber: 9,
    rowNumber: 4,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:62",
    name: "segment-4-10",
    imageSrc: "/images/segments/segment-4-10.webp",
    minU: 0.8333333333333334,
    maxU: 0.9165364583333333,
    minV: 0.37526041666666665,
    maxV: 0.5,
    columnNumber: 10,
    rowNumber: 4,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:63",
    name: "segment-4-11",
    imageSrc: "/images/segments/segment-4-11.webp",
    minU: 0.9166666666666666,
    maxU: 0.9998697916666667,
    minV: 0.37526041666666665,
    maxV: 0.5,
    columnNumber: 11,
    rowNumber: 4,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:64",
    name: "segment-5-0",
    imageSrc: "/images/segments/segment-5-0.webp",
    minU: 0,
    maxU: 0.083203125,
    minV: 0.25026041666666665,
    maxV: 0.375,
    columnNumber: 0,
    rowNumber: 5,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:65",
    name: "segment-5-1",
    imageSrc: "/images/segments/segment-5-1.webp",
    minU: 0.08333333333333333,
    maxU: 0.16653645833333333,
    minV: 0.25026041666666665,
    maxV: 0.375,
    columnNumber: 1,
    rowNumber: 5,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:66",
    name: "segment-5-2",
    imageSrc: "/images/segments/segment-5-2.webp",
    minU: 0.16666666666666666,
    maxU: 0.24986979166666667,
    minV: 0.25026041666666665,
    maxV: 0.375,
    columnNumber: 2,
    rowNumber: 5,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:67",
    name: "segment-5-3",
    imageSrc: "/images/segments/segment-5-3.webp",
    minU: 0.25,
    maxU: 0.333203125,
    minV: 0.25026041666666665,
    maxV: 0.375,
    columnNumber: 3,
    rowNumber: 5,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:68",
    name: "segment-5-4",
    imageSrc: "/images/segments/segment-5-4.webp",
    minU: 0.3333333333333333,
    maxU: 0.41653645833333336,
    minV: 0.25026041666666665,
    maxV: 0.375,
    columnNumber: 4,
    rowNumber: 5,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:69",
    name: "segment-5-5",
    imageSrc: "/images/segments/segment-5-5.webp",
    minU: 0.4166666666666667,
    maxU: 0.4998697916666667,
    minV: 0.25026041666666665,
    maxV: 0.375,
    columnNumber: 5,
    rowNumber: 5,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:70",
    name: "segment-5-6",
    imageSrc: "/images/segments/segment-5-6.webp",
    minU: 0.5,
    maxU: 0.583203125,
    minV: 0.25026041666666665,
    maxV: 0.375,
    columnNumber: 6,
    rowNumber: 5,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:71",
    name: "segment-5-7",
    imageSrc: "/images/segments/segment-5-7.webp",
    minU: 0.5833333333333334,
    maxU: 0.6665364583333333,
    minV: 0.25026041666666665,
    maxV: 0.375,
    columnNumber: 7,
    rowNumber: 5,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:72",
    name: "segment-5-8",
    imageSrc: "/images/segments/segment-5-8.webp",
    minU: 0.6666666666666666,
    maxU: 0.7498697916666667,
    minV: 0.25026041666666665,
    maxV: 0.375,
    columnNumber: 8,
    rowNumber: 5,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:73",
    name: "segment-5-9",
    imageSrc: "/images/segments/segment-5-9.webp",
    minU: 0.75,
    maxU: 0.833203125,
    minV: 0.25026041666666665,
    maxV: 0.375,
    columnNumber: 9,
    rowNumber: 5,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:74",
    name: "segment-5-10",
    imageSrc: "/images/segments/segment-5-10.webp",
    minU: 0.8333333333333334,
    maxU: 0.9165364583333333,
    minV: 0.25026041666666665,
    maxV: 0.375,
    columnNumber: 10,
    rowNumber: 5,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:75",
    name: "segment-5-11",
    imageSrc: "/images/segments/segment-5-11.webp",
    minU: 0.9166666666666666,
    maxU: 0.9998697916666667,
    minV: 0.25026041666666665,
    maxV: 0.375,
    columnNumber: 11,
    rowNumber: 5,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:76",
    name: "segment-6-0",
    imageSrc: "/images/segments/segment-6-0.webp",
    minU: 0,
    maxU: 0.083203125,
    minV: 0.12526041666666665,
    maxV: 0.25,
    columnNumber: 0,
    rowNumber: 6,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:77",
    name: "segment-6-1",
    imageSrc: "/images/segments/segment-6-1.webp",
    minU: 0.08333333333333333,
    maxU: 0.16653645833333333,
    minV: 0.12526041666666665,
    maxV: 0.25,
    columnNumber: 1,
    rowNumber: 6,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:78",
    name: "segment-6-2",
    imageSrc: "/images/segments/segment-6-2.webp",
    minU: 0.16666666666666666,
    maxU: 0.24986979166666667,
    minV: 0.12526041666666665,
    maxV: 0.25,
    columnNumber: 2,
    rowNumber: 6,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:79",
    name: "segment-6-3",
    imageSrc: "/images/segments/segment-6-3.webp",
    minU: 0.25,
    maxU: 0.333203125,
    minV: 0.12526041666666665,
    maxV: 0.25,
    columnNumber: 3,
    rowNumber: 6,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:80",
    name: "segment-6-4",
    imageSrc: "/images/segments/segment-6-4.webp",
    minU: 0.3333333333333333,
    maxU: 0.41653645833333336,
    minV: 0.12526041666666665,
    maxV: 0.25,
    columnNumber: 4,
    rowNumber: 6,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:81",
    name: "segment-6-5",
    imageSrc: "/images/segments/segment-6-5.webp",
    minU: 0.4166666666666667,
    maxU: 0.4998697916666667,
    minV: 0.12526041666666665,
    maxV: 0.25,
    columnNumber: 5,
    rowNumber: 6,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:82",
    name: "segment-6-6",
    imageSrc: "/images/segments/segment-6-6.webp",
    minU: 0.5,
    maxU: 0.583203125,
    minV: 0.12526041666666665,
    maxV: 0.25,
    columnNumber: 6,
    rowNumber: 6,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:83",
    name: "segment-6-7",
    imageSrc: "/images/segments/segment-6-7.webp",
    minU: 0.5833333333333334,
    maxU: 0.6665364583333333,
    minV: 0.12526041666666665,
    maxV: 0.25,
    columnNumber: 7,
    rowNumber: 6,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:84",
    name: "segment-6-8",
    imageSrc: "/images/segments/segment-6-8.webp",
    minU: 0.6666666666666666,
    maxU: 0.7498697916666667,
    minV: 0.12526041666666665,
    maxV: 0.25,
    columnNumber: 8,
    rowNumber: 6,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:85",
    name: "segment-6-9",
    imageSrc: "/images/segments/segment-6-9.webp",
    minU: 0.75,
    maxU: 0.833203125,
    minV: 0.12526041666666665,
    maxV: 0.25,
    columnNumber: 9,
    rowNumber: 6,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:86",
    name: "segment-6-10",
    imageSrc: "/images/segments/segment-6-10.webp",
    minU: 0.8333333333333334,
    maxU: 0.9165364583333333,
    minV: 0.12526041666666665,
    maxV: 0.25,
    columnNumber: 10,
    rowNumber: 6,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:87",
    name: "segment-6-11",
    imageSrc: "/images/segments/segment-6-11.webp",
    minU: 0.9166666666666666,
    maxU: 0.9998697916666667,
    minV: 0.12526041666666665,
    maxV: 0.25,
    columnNumber: 11,
    rowNumber: 6,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:88",
    name: "segment-7-0",
    imageSrc: "/images/segments/segment-7-0.webp",
    minU: 0,
    maxU: 0.083203125,
    minV: 0.00026041666666665186,
    maxV: 0.125,
    columnNumber: 0,
    rowNumber: 7,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:89",
    name: "segment-7-1",
    imageSrc: "/images/segments/segment-7-1.webp",
    minU: 0.08333333333333333,
    maxU: 0.16653645833333333,
    minV: 0.00026041666666665186,
    maxV: 0.125,
    columnNumber: 1,
    rowNumber: 7,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:90",
    name: "segment-7-2",
    imageSrc: "/images/segments/segment-7-2.webp",
    minU: 0.16666666666666666,
    maxU: 0.24986979166666667,
    minV: 0.00026041666666665186,
    maxV: 0.125,
    columnNumber: 2,
    rowNumber: 7,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:91",
    name: "segment-7-3",
    imageSrc: "/images/segments/segment-7-3.webp",
    minU: 0.25,
    maxU: 0.333203125,
    minV: 0.00026041666666665186,
    maxV: 0.125,
    columnNumber: 3,
    rowNumber: 7,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:92",
    name: "segment-7-4",
    imageSrc: "/images/segments/segment-7-4.webp",
    minU: 0.3333333333333333,
    maxU: 0.41653645833333336,
    minV: 0.00026041666666665186,
    maxV: 0.125,
    columnNumber: 4,
    rowNumber: 7,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:93",
    name: "segment-7-5",
    imageSrc: "/images/segments/segment-7-5.webp",
    minU: 0.4166666666666667,
    maxU: 0.4998697916666667,
    minV: 0.00026041666666665186,
    maxV: 0.125,
    columnNumber: 5,
    rowNumber: 7,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:94",
    name: "segment-7-6",
    imageSrc: "/images/segments/segment-7-6.webp",
    minU: 0.5,
    maxU: 0.583203125,
    minV: 0.00026041666666665186,
    maxV: 0.125,
    columnNumber: 6,
    rowNumber: 7,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:95",
    name: "segment-7-7",
    imageSrc: "/images/segments/segment-7-7.webp",
    minU: 0.5833333333333334,
    maxU: 0.6665364583333333,
    minV: 0.00026041666666665186,
    maxV: 0.125,
    columnNumber: 7,
    rowNumber: 7,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:96",
    name: "segment-7-8",
    imageSrc: "/images/segments/segment-7-8.webp",
    minU: 0.6666666666666666,
    maxU: 0.7498697916666667,
    minV: 0.00026041666666665186,
    maxV: 0.125,
    columnNumber: 8,
    rowNumber: 7,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:97",
    name: "segment-7-9",
    imageSrc: "/images/segments/segment-7-9.webp",
    minU: 0.75,
    maxU: 0.833203125,
    minV: 0.00026041666666665186,
    maxV: 0.125,
    columnNumber: 9,
    rowNumber: 7,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:98",
    name: "segment-7-10",
    imageSrc: "/images/segments/segment-7-10.webp",
    minU: 0.8333333333333334,
    maxU: 0.9165364583333333,
    minV: 0.00026041666666665186,
    maxV: 0.125,
    columnNumber: 10,
    rowNumber: 7,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
  {
    id: "4:94060cd9-de11-422d-8e9a-620bb5254b30:99",
    name: "segment-7-11",
    imageSrc: "/images/segments/segment-7-11.webp",
    minU: 0.9166666666666666,
    maxU: 0.9998697916666667,
    minV: 0.00026041666666665186,
    maxV: 0.125,
    columnNumber: 11,
    rowNumber: 7,
    dataPoint: "4:94060cd9-de11-422d-8e9a-620bb5254b30:199",
  },
];

function Panorama() {
  const sphereRef = useRef<THREE.Mesh<
    THREE.SphereGeometry,
    THREE.MeshStandardMaterial
  > | null>(null);
  const { isPresenting } = useXR();
  const { intersectedGlobe } = useSceneLogic({ globeRef: sphereRef });
  const segmentTexture = useRef<THREE.Texture | null>(null);
  const pointOfIntersectionRef = useRef<THREE.Vector2>(null);
  const currentMatchingSegmentIDRef = useRef<string>(null);

  useFrame(() => {
    if (!intersectedGlobe.current || !sphereRef.current) return;

    const point = intersectedGlobe.current.uv;

    if (!pointOfIntersectionRef.current) {
      /**
       * Loading environment for the 1st time
       */
      pointOfIntersectionRef.current = point;

      const matchingSegment = imageList.find(
        (image) =>
          pointOfIntersectionRef.current.x >= image.minU &&
          pointOfIntersectionRef.current.x <= image.maxU &&
          pointOfIntersectionRef.current.y >= image.minV &&
          pointOfIntersectionRef.current.y <= image.maxV
      );

      if (matchingSegment) {
        currentMatchingSegmentIDRef.current = matchingSegment.id;

        const loader = new TextureLoader();
        loader.load(matchingSegment.imageSrc, (texture) => {
          segmentTexture.current = texture;
          texture.minFilter = THREE.NearestFilter;
          texture.generateMipmaps = false;

          if (sphereRef.current) {
            sphereRef.current.material.map = texture;
            sphereRef.current.material.needsUpdate = true;
          }
        });
      }
    } else {
      /**
       * Environment already loaded
       * New Point of Intersection Detected!
       */
      if (
        JSON.stringify(point) !== JSON.stringify(pointOfIntersectionRef.current)
      ) {
        const matchingSegment = imageList.find(
          (image) =>
            point.x >= image.minU &&
            point.x <= image.maxU &&
            point.y >= image.minV &&
            point.y <= image.maxV
        );

        if (
          matchingSegment &&
          matchingSegment.id !== currentMatchingSegmentIDRef.current
        ) {
          currentMatchingSegmentIDRef.current = matchingSegment.id;

          const loader = new TextureLoader();
          loader.load(matchingSegment.imageSrc, (texture) => {
            segmentTexture.current = texture;
            texture.minFilter = THREE.NearestFilter;
            texture.generateMipmaps = false;

            if (sphereRef.current) {
              sphereRef.current.material.map = texture;
              sphereRef.current.material.needsUpdate = true;
            }
          });
        }
      }
    }
  });

  const material = useMemo(
    () =>
      new THREE.MeshStandardMaterial({
        side: THREE.BackSide,
      }),
    []
  );

  return (
    <mesh
      ref={sphereRef}
      position={[0, isPresenting ? 2.6 : 0, 0]}
      material={material}
    >
      <sphereGeometry args={[6, 250, 256]} />
    </mesh>
  );
}

export default function WebpSegmentDefaultShader() {
  return (
    <>
      <VRButton />
      <Canvas>
        <XR referenceSpace="local-floor">
          <ambientLight intensity={0.3} />
          <Panorama />
          <OrbitControls />
          <FPSCounter />
        </XR>
      </Canvas>
    </>
  );
}
