import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Canvas } from "@react-three/fiber";
import { VRButton, XR } from "@react-three/xr";
import { OrbitControls } from "@react-three/drei";
import Scene from "components/HighResolutionSegmentEnvironement/Scene";
import useWebSocket, { EventTypes } from "hooks/useWebSocket";
import useSingleProperty from "hooks/useSingleProperty";
import * as THREE from "three";

export default function HighResolutionSegmentGridEnvironment() {
  const { propertyId } = useParams<{ propertyId: string }>();
  const { property } = useSingleProperty(propertyId);
  const { socket, emitInitiateDataStreamingProcessEvent } = useWebSocket();
  const lowResTexture = useRef<THREE.Texture | null>();
  const depthTexture = useRef<THREE.Texture | null>();

  const [isLowResolutionLoaded, setIsLowResolutionLoaded] = useState(false);
  const [isDepthMapLoaded, setIsDepthMapLoaded] = useState(false);

  useEffect(() => {
    emitInitiateDataStreamingProcessEvent(propertyId);
  }, [propertyId]);

  useEffect(() => {
    if (socket) {
      socket.on(EventTypes.SYNC_STREAMING_DATA, (data: any) => {
        const { lowResolution, depthMapResolution } =
          data.streamingQueue.reduce(
            (acc, item) => {
              if (item.type === "low_resolution") {
                acc.lowResolution = item;
              } else if (item.type === "depth_map_resolution") {
                acc.depthMapResolution = item;
              }
              return acc;
            },
            { lowResolution: null, depthMapResolution: null }
          );

        const loader = new THREE.TextureLoader();

        const blobLowRes = new Blob([lowResolution.arrayBuffer], {
          type: "image/png",
        });
        const blobUrlLowRes = URL.createObjectURL(blobLowRes);
        loader.load(blobUrlLowRes, (texture) => {
          lowResTexture.current = texture;
          URL.revokeObjectURL(blobUrlLowRes);
          setIsLowResolutionLoaded(true);

          // 🌺🌺🌺 We can clear the loader to maybe free some memory
        });

        const blobDepth = new Blob([depthMapResolution.arrayBuffer], {
          type: "image/png",
        });
        const blobUrlDepth = URL.createObjectURL(blobDepth);
        loader.load(blobUrlDepth, (texture) => {
          depthTexture.current = texture;
          URL.revokeObjectURL(blobUrlDepth);
          setIsDepthMapLoaded(true);

          // 🌺🌺🌺 We can clear the loader to maybe free some memory
        });
      });
    } else {
      console.log("Socket is not initialized.");
    }

    return () => {
      if (socket) {
        socket.emit(EventTypes.DISCONNECT);
        socket.disconnect();
      }
    };
  }, [socket]);

  return (
    <>
      <VRButton />

      <Canvas
        frameloop="demand"
        dpr={[1, 1.5]}
        shadows
        camera={{
          fov: 100,
          position: [0, 0, 0.1],
        }}
        style={{ backgroundColor: "#000" }}
      >
        <XR referenceSpace="local-floor">
          <ambientLight intensity={10} />
          {isLowResolutionLoaded && isDepthMapLoaded && property && (
            <Scene
              property={property}
              lowResTexture={lowResTexture.current}
              depthTexture={depthTexture.current}
            />
          )}

          <OrbitControls />
        </XR>
      </Canvas>
    </>
  );
}
