import { useRef } from "react";
import { Canvas } from "@react-three/fiber";
import { XR, Controllers, Hands, VRButton } from "@react-three/xr";
import { OrbitControls } from "@react-three/drei";
import HighResWebpSphereModel from "components/HighResWebpSphereModel";

export default function Webp3DModel() {
  const sphereRef = useRef();

  return (
    <>
      <VRButton />
      <Canvas>
        <XR referenceSpace="local-floor">
          <ambientLight intensity={2} />
          {/* <pointLight position={[10, 10, 10]} /> */}
          <HighResWebpSphereModel ref={sphereRef} />
          <Controllers />
          <Hands />
          <OrbitControls />
        </XR>
      </Canvas>
    </>
  );
}
