import * as THREE from "three";
import useEnvironmentOuterGlobe from "hooks/useEnvironmentOuterGlobe";

function EnvironmentOuterGlobe({
  reference,
  intersectedGlobe,
  lowResImage,
  depthmapImage,
  userGettingAwayRef,
  dataPointList,
  currentDatapointIndex,
}) {
  const { texturesLoaded, vertexShader, fragmentShader, uniforms } =
    useEnvironmentOuterGlobe({
      reference,
      intersectedGlobe,
      lowResImage,
      depthmapImage,
      userGettingAwayRef,
      dataPointList,
      currentDatapointIndex,
    });

  if (!texturesLoaded) return null;

  return (
    <mesh ref={reference} rotation={[0, Math.PI / 2, 0]}>
      <sphereGeometry args={[60, 1000, 1000]} />
      <shaderMaterial
        args={[
          {
            uniforms,
            vertexShader,
            fragmentShader,
            side: THREE.BackSide,
          },
        ]}
      />
    </mesh>
  );
}

export default EnvironmentOuterGlobe;
