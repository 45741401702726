import { useRef, useState, useEffect, useMemo } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";
import useWebSocket, { EventTypes } from "hooks/useWebSocket";
import { StreamSegmentConfigType } from "types";

interface UseEnvironmentOuterGlobeParams {
  reference: React.MutableRefObject<THREE.Mesh>;
  intersectedGlobe: React.MutableRefObject<
    THREE.Intersection<THREE.Object3D> | undefined
  >;
  lowResTexture: THREE.Texture;
  depthTexture: THREE.Texture;
  dataPointId: string;
}

export default function useEnvironmentOuterGlobe({
  reference,
  intersectedGlobe,
  lowResTexture,
  depthTexture,
  dataPointId,
}: UseEnvironmentOuterGlobeParams) {
  const uThreshold = 30.0;
  const highResScale = 1.0;
  const { socket, publishGlobeIntersectionPoint } = useWebSocket();
  const [texturesLoaded, setTexturesLoaded] = useState(false);
  const [segmentTexture, setSegmentTexture] = useState<THREE.Texture | null>(
    null
  );
  const lastLoadedSegmentImage = useRef<{
    columnNumber: number;
    rowNumber: number;
  }>(null);
  const uvCoordinateRef = useRef<{ u: number; v: number } | null>(null);

  useFrame(() => {
    if (!reference.current) return;
    if (!intersectedGlobe.current || intersectedGlobe.current.faceIndex < 0)
      return;

    const { width: u, height: v } = intersectedGlobe.current.uv;
    const columnNumber = lastLoadedSegmentImage.current?.columnNumber ?? null;
    const rowNumber = lastLoadedSegmentImage.current?.rowNumber ?? null;

    if (!uvCoordinateRef.current) {
      console.log("\n\n\n ✅ Setting uv coordinates for the first time!");

      console.log("🌺 Publishing coordinates to server...");
      publishGlobeIntersectionPoint(u, v, dataPointId, columnNumber, rowNumber);

      console.log("🌺 Setting uv coordinates... \n\n\n");
      uvCoordinateRef.current = { u, v };
    } else if (
      Math.abs(uvCoordinateRef.current.u - u) > 0.0001 ||
      Math.abs(uvCoordinateRef.current.v - v) > 0.0001
    ) {
      console.log("\n\n\n ⭐️ Noticed uv coordinates change!");

      console.log("🌺 Publishing updated coordinates to server...");
      publishGlobeIntersectionPoint(u, v, dataPointId, columnNumber, rowNumber);

      console.log("🌺 Updating uv coordinates... \n\n\n");
      uvCoordinateRef.current.u = u;
      uvCoordinateRef.current.v = v;
    }

    const geometry = reference.current.geometry;
    const faceIndex = intersectedGlobe.current.faceIndex;
    let faceIndices: number[];
    if (geometry.index) {
      const arr = geometry.index.array;
      faceIndices = [
        arr[faceIndex * 3],
        arr[faceIndex * 3 + 1],
        arr[faceIndex * 3 + 2],
      ];
    } else {
      faceIndices = [faceIndex * 3, faceIndex * 3 + 1, faceIndex * 3 + 2];
    }

    const positions = geometry.attributes.position.array as Float32Array;
    const faceA = new THREE.Vector3(
      positions[faceIndices[0] * 3],
      positions[faceIndices[0] * 3 + 1],
      positions[faceIndices[0] * 3 + 2]
    );
    const faceB = new THREE.Vector3(
      positions[faceIndices[1] * 3],
      positions[faceIndices[1] * 3 + 1],
      positions[faceIndices[1] * 3 + 2]
    );
    const faceC = new THREE.Vector3(
      positions[faceIndices[2] * 3],
      positions[faceIndices[2] * 3 + 1],
      positions[faceIndices[2] * 3 + 2]
    );

    const uniforms = (reference.current.material as any).uniforms;
    uniforms.uFaceA.value.copy(faceA);
    uniforms.uFaceB.value.copy(faceB);
    uniforms.uFaceC.value.copy(faceC);
    uniforms.uIntersectedFaceIndex.value = faceIndex;
  });

  useEffect(() => {
    if (!socket) return;

    const handleStreamSegment = (data: StreamSegmentConfigType) => {
      const { columnNumber, rowNumber } = data;
      const blob = new Blob([data.imageBuffer], { type: "image/png" });
      const blobUrl = URL.createObjectURL(blob);
      lastLoadedSegmentImage.current = { columnNumber, rowNumber };

      const textureLoader = new THREE.TextureLoader();

      textureLoader.load(blobUrl, (loadedTexture) => {
        console.log("loadedTexture: ", loadedTexture);
        setSegmentTexture(loadedTexture);
        URL.revokeObjectURL(blobUrl);
      });
    };

    socket.on(EventTypes.STREAM_SEGMENT_IMAGE, handleStreamSegment);

    return () => {
      if (socket) {
        socket.emit(EventTypes.DISCONNECT);
        socket.disconnect();
      }
    };
  }, [socket]);

  useEffect(() => {
    if (lowResTexture && depthTexture) {
      setTexturesLoaded(true);
    }
  }, [lowResTexture, depthTexture]);

  const vertexShader = `
    varying vec3 vPosition;
    varying vec2 vUv;
    varying float vDistance;
    uniform int uIntersectedFaceIndex;
    uniform vec3 uFaceA;
    uniform vec3 uFaceB;
    uniform vec3 uFaceC;
    uniform float uThreshold;
    uniform sampler2D depthMap;
    uniform float displacementScale;
    uniform float minDepth;
    uniform float maxDepth;

    vec3 calculateCentroid(vec3 a, vec3 b, vec3 c) {
      return (a + b + c) / 3.0;
    }

    void main() {
      vUv = uv;
      vPosition = position;

      vec4 depthData = texture2D(depthMap, uv);
      float vDisplacement = depthData.r * displacementScale;
      vec3 transformedPosition = position + normal * vDisplacement;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(transformedPosition, 1.0);

      if (uIntersectedFaceIndex >= 0) {
        vec3 faceTriangleCentroid = calculateCentroid(uFaceA, uFaceB, uFaceC);
        vDistance = length(vPosition - faceTriangleCentroid);
      } else {
        vDistance = 1.0;
      }
    }
  `;

  const fragmentShader = `
    varying vec2 vUv;
    varying vec3 vPosition;
    varying float vDistance;
    uniform sampler2D uLowResTexture;
    uniform sampler2D uHighResTexture;
    uniform int uIntersectedFaceIndex;
    uniform float uThreshold;
    uniform float minDepth;
    uniform float maxDepth;
    uniform float highResScale;

    void main() {
      vec3 color = texture2D(uLowResTexture, vUv).rgb;
      vec2 scaledUv = vUv * highResScale;
      vec4 highResColor = texture2D(uHighResTexture, scaledUv);
      if (highResColor.a > 0.0) {
        color = highResColor.rgb;
      }

      float circleRadius = 2.0;
      if (vDistance < circleRadius && uIntersectedFaceIndex >= 0) {
        color = vec3(1.0, 1.0, 1.0);
      }

      gl_FragColor = vec4(color, 1.0);
    }
  `;

  const uniforms = useMemo(
    () => ({
      uLowResTexture: { value: lowResTexture },
      depthMap: { value: depthTexture },
      uHighResTexture: { value: segmentTexture || new THREE.Texture() },
      displacementScale: { value: 20 },
      uIntersectedFaceIndex: { value: -1 },
      uFaceA: { value: new THREE.Vector3() },
      uFaceB: { value: new THREE.Vector3() },
      uFaceC: { value: new THREE.Vector3() },
      uThreshold: { value: uThreshold },
      minDepth: { value: 50.0 },
      maxDepth: { value: 140.0 },
      highResScale: { value: highResScale },
    }),
    [lowResTexture, depthTexture, segmentTexture, uThreshold, highResScale]
  );

  return {
    texturesLoaded,
    vertexShader,
    fragmentShader,
    uniforms,
  };
}
