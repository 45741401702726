import { Link } from "react-router-dom";
import { cn } from "utils/helper";
import useAllProperties from "hooks/useAllProperties";
import LoadingSpinner from "components/LoadingSpinner";

export default function Home() {
  const { properties, loading, error } = useAllProperties();

  if (loading) {
    return (
      <div className="w-screen h-screen bg-blue-950 flex flex-col justify-center items-center gap-12 p-4">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-screen h-screen bg-blue-950 flex flex-col justify-center items-center gap-12 p-4">
        <p className="text-red-300 text-2xl">Error: {error}</p>
      </div>
    );
  }

  return (
    <div className="w-screen min-h-screen bg-blue-950 text-white flex flex-col items-center overflow-y-auto">
      {/* Hero Section */}
      <div className="w-full max-w-screen-xl px-4 py-8 md:py-16 flex flex-col items-center text-center">
        <h1 className="text-3xl md:text-5xl font-extrabold mb-6">TrueVue Reality</h1>
        <h3 className="text-lg md:text-2xl text-slate-300 mb-10">
          Visit our property virtual experiences
        </h3>
      </div>

      {/* Property Cards Section */}
      <div className="w-full max-w-screen-xl px-4 pb-8">
        <div
          className="
            grid 
            grid-cols-1 
            sm:grid-cols-2 
            md:grid-cols-3 
            lg:grid-cols-4 
            gap-6
          "
        >
          {!loading && properties?.map((property, index) => (
            <Link
              to={`/high-resolution-segment-grid-environment/${property.id}`}
              key={index}
              className={cn(
                "flex flex-col justify-center items-center p-6 transition transform",
                "rounded-lg border-2 border-teal-200 bg-gradient-to-br from-blue-800 via-blue-900 to-blue-950",
                "text-teal-200 font-semibold text-lg md:text-xl",
                "hover:border-teal-300 hover:from-blue-700 hover:via-blue-800 hover:to-blue-900",
                "hover:shadow-xl hover:scale-105 hover:text-teal-100"
              )}
            >
              <span className="mb-2">{property.title}</span>
            </Link>
          ))}
        </div>


        {
          !loading && properties.length === 0 && (
            <div className="gap-12 p-4 text-white text-center">
              No Data
            </div>
          )
        }
      </div>
    </div>
  );
}
