import { useRef } from "react";
import * as THREE from "three";
import useSceneLogic from "./useSceneLogic";
import useEnvironmentOuterGlobe from "./useEnvironmentOuterGlobe";
import { Property } from "types";

type Props = {
  property: Property;
  lowResTexture: THREE.Texture;
  depthTexture: THREE.Texture;
};

export default function Scene({
  property,
  lowResTexture,
  depthTexture,
}: Props) {
  const dataPointId = property.regions[0].datapoints[0].id;
  const globeRef = useRef<THREE.Mesh>(null!);

  const { intersectedGlobe } = useSceneLogic({ globeRef });

  const { texturesLoaded, vertexShader, fragmentShader, uniforms } =
    useEnvironmentOuterGlobe({
      reference: globeRef,
      intersectedGlobe,
      lowResTexture,
      depthTexture,
      dataPointId,
    });

  if (!texturesLoaded) return null;

  return (
    <mesh ref={globeRef} rotation={[0, Math.PI / 2, 0]}>
      <sphereGeometry args={[60, 100, 100]} />
      <shaderMaterial
        args={[
          {
            uniforms,
            vertexShader,
            fragmentShader,
            side: THREE.BackSide,
          },
        ]}
      />
    </mesh>
  );
}
