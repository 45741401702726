import { createBrowserRouter } from "react-router-dom";
import Home from "pages/Home";
import StereoscopicView from "pages/StereoscopicView";
import "./global.css";
import ThreeDimensionEnvironment from "pages/ThreeDimensionEnvironment";
import Development from "pages/Development";
import DepthMap from "pages/DepthMap";
import Development2 from "pages/Development2";
import Development3 from "pages/Development3";
import Development4 from "pages/Development4";
import DepthMapStreetView from "pages/DepthMapStreetView";
import DevelopmentHome from "pages/DevelopmentHome";
import PropertyExperience from "pages/PropertyExperience";
import HighResolutionSegmentGridEnvironment from "pages/HighResolutionSegmentGridEnvironment";
import WebpSegmentDefaultShader from "pages/final/WebpSegmentDefaultShader";
import Webp3DModel from "pages/Webp3DModel";
import WebpElevenKModel from "pages/WebpElevenKModel";
import TokyoNight from "pages/final/Tokyo-Night";
import TokyoNightWithDephtmap from "pages/final/Tokyo-Night-With-Dephtmap";
import FinalHome from "pages/FinalHome";
import MountainBeach from "pages/final/Mountain-Beach";
import MountainBeachWithDephtmap from "pages/final/Mountain-Beach-With-Dephtmap";
import ArturoReplacedWithTokyoNight from "pages/final/Arturo-replaced-with-Tokyo-Night";
import ArturoReplacedWithTokyoNightWithDepthmap from "pages/final/Arturo-replaced-with-Tokyo-Night-with-Depthmap";
import ArturoReplacedWithTokyoNightPng from "pages/final/Arturo-replaced-with-Tokyo-Png";
import ArturoReplacedWithTokyoNightWithDepthmapPng from "pages/final/Arturo-replaced-with-Tokyo-Night-with-Depthmap-Png";
import Arturo3DModelWebp from "pages/final/Arturo-3D-Model-Webp";
import ArturoOneSegmentReplacedWithTokyoNight from "pages/final/Arturo-one-segment-replaced-with-Tokyo-Night";
import WebpDatapointsWithHandTracking from "pages/final/Webp-Datapoints-with-hand-tracking";
import WebpDatapointsWithHandTrackingWithDepthmap from "pages/final/Webp-Datapoints-with-hand-tracking-with-depthmap";
import WebpSegmentCustomShader from "pages/final/WebpSegmentCustomShader";
import WebpSegmentDefaultShaderWithDataPreloading from "pages/final/Webp-Segment-Default-Shader-With-Data-Preloading";
import WebpSegmentCustomShaderWithDataPreloading from "pages/final/Webp-Segment-Custom-Shader-With-Date-Preloading";
import HighResSegmentOverlayedOverLowResImageByDefault from "pages/final/High-res-segment-overlayed-over-low-res-image-by-default";

const router = createBrowserRouter([
  // Final
  {
    path: "/final-home",
    element: <FinalHome />,
  },
  {
    path: "/final/tokyo-night",
    element: <TokyoNight />,
  },
  {
    path: "/final/tokyo-night-with-depthmap",
    element: <TokyoNightWithDephtmap />,
  },
  {
    path: "/final/mountain-beach",
    element: <MountainBeach />,
  },
  {
    path: "/final/mountain-beach-with-depthmap",
    element: <MountainBeachWithDephtmap />,
  },
  {
    path: "/final/arturo-replaced-with-tokyo-night",
    element: <ArturoReplacedWithTokyoNight />,
  },
  {
    path: "/final/arturo-replaced-with-tokyo-night-with-depthmap",
    element: <ArturoReplacedWithTokyoNightWithDepthmap />,
  },
  {
    path: "/final/arturo-replaced-with-tokyo-night-png",
    element: <ArturoReplacedWithTokyoNightPng />,
  },
  {
    path: "/final/arturo-replaced-with-tokyo-night-with-depthmap-png",
    element: <ArturoReplacedWithTokyoNightWithDepthmapPng />,
  },
  {
    path: "/final/arturo-3d-model-webp",
    element: <Arturo3DModelWebp />,
  },
  {
    path: "/final/arturo-one-segment-3d-model-webp",
    element: <ArturoOneSegmentReplacedWithTokyoNight />,
  },
  {
    path: "/final/webp-datapoints-with-hand-tracking",
    element: <WebpDatapointsWithHandTracking />,
  },
  {
    path: "/final/webp-datapoints-with-hand-tracking-with-depthmap",
    element: <WebpDatapointsWithHandTrackingWithDepthmap />,
  },
  {
    path: "/final/webp-segment-default-shader",
    element: <WebpSegmentDefaultShader />,
  },
  {
    path: "/final/webp-segment-custom-shader",
    element: <WebpSegmentCustomShader />,
  },
  {
    path: "/final/webp-segment-default-shader-with-data-preloading",
    element: <WebpSegmentDefaultShaderWithDataPreloading />,
  },
  {
    path: "/final/webp-segment-custom-shader-with-data-preloading",
    element: <WebpSegmentCustomShaderWithDataPreloading />,
  },
  {
    path: "/final/high-res-segment-overlayed-over-low-res-image-by-default",
    element: <HighResSegmentOverlayedOverLowResImageByDefault />,
  },
  {
    path: "/final/complete-system-with-segments-technology",
    element: <Home />,
  },


  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/property-experience/:propertyId",
    element: <PropertyExperience />,
  },
  {
    path: "/webp-3d-model",
    element: <Webp3DModel />,
  },
  {
    path: "/webp-11k-model",
    element: <WebpElevenKModel />,
  },
  {
    path: "/high-resolution-segment-grid-environment/:propertyId",
    element: <HighResolutionSegmentGridEnvironment />,
  },
  {
    path: "/dev-home",
    element: <DevelopmentHome />,
  },
  {
    path: "/development",
    element: <Development />,
  },
  {
    path: "/development2",
    element: <Development2 />,
  },
  {
    path: "/development3",
    element: <Development3 />,
  },
  {
    path: "/development4",
    element: <Development4 />,
  },
  {
    path: "/depthmap-street-view",
    element: <DepthMapStreetView />,
  },
  {
    path: "/stereoscopic-view",
    element: <StereoscopicView />,
  },
  {
    path: "/3d-model-environment",
    element: <ThreeDimensionEnvironment />,
  },
  {
    path: "/depth-map",
    element: <DepthMap />,
  },
]);

export default router;
