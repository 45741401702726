import { memo } from "react";
import { Config, DataPointType } from "utils/types";
import useSceneLogic from "hooks/useSceneLogic";
import DataPoint from "./DataPoint";
import RoomFloor from "./Floor/RoomFloor";
import Opening from "./Floor/Opening";
import EnvironmentOuterGlobe from "./EnvironmentOuterGlobe";

type Props = {
  propertyConfig: Config[];
  dataPointList: DataPointType[];
  openingList: any[];
  currentDatapointIndex: number;
  setCurrentDatapointIndex: any;
  globeRef: any;
};

const MemorizedEnvironmentOuterGlobe = memo(EnvironmentOuterGlobe);

function Scene(props: Props) {
  const {
    currentDatapointIndex,
    setCurrentDatapointIndex,
    propertyConfig,
    dataPointList,
    openingList,
    globeRef,
  } = props;

  console.log("🌺🌺🌺🌺🌺 RE-RENDERING Scene")

  const {
    devPlayerRef,
    roomRefs,
    openingRefs,
    roomIntersectionArrowRef,
    boxRef,
    userGettingAwayRef,
    filteredDataPoints,
    intersectedGlobe,
  } = useSceneLogic({
    currentDatapointIndex,
    setCurrentDatapointIndex,
    propertyConfig,
    dataPointList,
    openingList,
    globeRef,
  });

  return (
    <>
      {/* Render filtered data points in group with bounding box */}
      <group ref={boxRef}>
        {filteredDataPoints?.map((item, index) => (
          <DataPoint
            key={index}
            index={index}
            state={item}
            currentDatapointIndex={currentDatapointIndex}
            userGettingAwayRef={userGettingAwayRef}
          />
        ))}
      </group>

      {/* Render floors */}
      {/* {propertyConfig.map((room, index) => (
        <RoomFloor
          key={room.id}
          name={room.id}
          position={room.position}
          roomRef={(el) => (roomRefs.current[index] = el)}
          points={room.regionCoordinates}
          color={room.regionColor}
        />
      ))} */}
      {/* {propertyConfig.map((room, index) => (
        <RoomFloor
          key={room.id}
          config={room}
          roomRef={(el) => (roomRefs.current[index] = el)}
        />
      ))} */}

      {/* Render openings */}
      {openingList.map((opening, index) => (
        <Opening
          key={opening.id}
          name={opening.id}
          openingRef={(el) => (openingRefs.current[index] = el)}
          coordinates={opening.coordinates}
          position={opening.positions}
        />
      ))}

      {/* Outer Globe environment using the memoized component */}
      <MemorizedEnvironmentOuterGlobe
        reference={globeRef}
        intersectedGlobe={intersectedGlobe}
        lowResImage={dataPointList[currentDatapointIndex].lowResArrayBuffer}
        depthmapImage={dataPointList[currentDatapointIndex].depthmapArrayBuffer}
        userGettingAwayRef={userGettingAwayRef}
        dataPointList={dataPointList}
        currentDatapointIndex={currentDatapointIndex}
      />

      {/* Dev player mesh (purple box) */}
      <mesh ref={devPlayerRef} position={[0, 0, 0]}>
        <boxGeometry args={[2, 2, 2]} />
        <meshBasicMaterial color="purple" transparent />
      </mesh>
    </>
  );
}

export default Scene;
