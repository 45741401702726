import { Suspense, useState, useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { XR, Controllers, Hands, VRButton, useXR } from "@react-three/xr";
import { OrbitControls, Preload, Html, Text } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import * as THREE from "three";
import { useStore } from "utils/store";
import { Vector3 } from "three";
import DefaultHandControllers from "components/HandTracking/DefaultHandControllers";
import FPSCounter from "components/FPSCounter";
import Loader from "components/Loader";

function Scene() {
  const [viewerPosition, setViewerPosition] = useState(new Vector3());
  const { player, session, referenceSpace } = useXR();

  useFrame(() => {
    if (!session) return;

    session.requestReferenceSpace(referenceSpace).then((refSpace: any) => {
      session.requestAnimationFrame((_, xrFrame) => {
        const pose = xrFrame.getViewerPose(refSpace);
        if (pose) {
          const { x, y, z } = pose.transform.position;
          setViewerPosition(new Vector3(x, y, z));
        }
      });
    });
  });

  return (
    <>
      <Text
        position={[
          player.position.x,
          player.position.y + 3,
          player.position.z - 5,
        ]}
        fontSize={0.5}
        color="#FF6581"
      >
        {`Position: ${viewerPosition.x.toFixed(2)}, ${viewerPosition.y.toFixed(
          2
        )}, ${viewerPosition.z.toFixed(2)}`}
      </Text>

      <Text
        position={[
          player.position.x,
          player.position.y + 4,
          player.position.z - 5,
        ]}
        fontSize={0.5}
        color="#62FF33"
      >
        {`Player Position: ${player.position.x.toFixed(2)}, ${player.position.y.toFixed(
          2
        )}, ${player.position.z.toFixed(2)}`}
      </Text>
    </>
  );
}

function Experience({ name, position, texture, depthmapTexture, onClick }) {
  const [hover, setHover] = useState(false);
  const materialRef = useRef<any>();

  useFrame(() => {
    if (materialRef.current && depthmapTexture) {
      materialRef.current.depthMap = depthmapTexture;
      materialRef.current.needsUpdate = true;
    }
  });

  return (
    <group>
      <mesh rotation={[0, Math.PI / 2, 0]}>
        <sphereGeometry args={[500, 60, 40]} />
        <meshStandardMaterial
          ref={materialRef}
          map={texture}
          side={THREE.BackSide}
          displacementMap={depthmapTexture}
          displacementScale={-200.0}
        />
      </mesh>

      <mesh position={position}>
        <sphereGeometry args={[1.25, 32, 32]} />
        <meshBasicMaterial color={hover ? "red" : "blue"} />
        <Html center>
          <a onClick={() => onClick(name)} style={{ cursor: "pointer" }}>
            {name}
          </a>
        </Html>
      </mesh>
    </group>
  );
}

function Portals() {
  const gap = 20;
  const [environmentList, setEnvironmentList] = useState([
    {
      name: "1",
      color: "lightblue",
      position: [2, 1.7, 1 * gap],
      url: "/images/arturo-standing-datapoints-webp-images/0001.webp",
      depthmap: "/images/arturo-standing-datapoints-webp-images/depthmap-0001.webp",
    },
    {
      name: "2",
      color: "lightblue",
      position: [2, 1.7, 2 * gap],
      url: "/images/arturo-standing-datapoints-webp-images/0002.webp",
      depthmap: "/images/arturo-standing-datapoints-webp-images/depthmap-0002.webp",
    },
  ]);

  const [current, setCurrent] = useState(0);
  const textures = useLoader(
    THREE.TextureLoader,
    environmentList.map((entry) => entry.url)
  );

  const depthmapTextures = useLoader(
    THREE.TextureLoader,
    environmentList.map((entry) => entry.depthmap)
  );

  const isMoveGestureDetected = useStore(
    (store) => store.isMoveGestureDetected
  );
  const { player } = useXR();

  const handleClick = (name) => {
    if (isMoveGestureDetected) {
      const clickedIndex = environmentList.findIndex(
        (item) => item.name === name
      );

      if (clickedIndex !== -1) {
        setCurrent(clickedIndex);
      }
    }
  };

  useFrame(() => {
    let distancesList = [];
    for (let index = 0; index < environmentList.length; index++) {
      const element = environmentList[index];

      if (index !== current) {
        const distance = player.position.distanceTo(
          new THREE.Vector3(
            element.position[0],
            element.position[1],
            element.position[2]
          )
        );
        distancesList.push({
          name: element.name,
          distance,
        });

        if (distance < 5) {
          setCurrent(index);
        }
      }
    }
  });

  return (
    <>
      {environmentList.map(
        (item, index) =>
          current !== index && (
            <Experience
              key={item.name}
              onClick={handleClick}
              name={item.name}
              position={item.position}
              texture={textures[index]}
              depthmapTexture={depthmapTextures[index]}
            />
          )
      )}
    </>
  );
}

export default function WebpDatapointsWithHandTrackingWithDepthmap() {
  return (
    <>
      <VRButton />

      <Canvas>
        <XR referenceSpace="local-floor">
          <Suspense fallback={<Loader />}>
            <ambientLight intensity={2} />
            <spotLight
              position={[0, 20, 10]}
              angle={0.3}
              penumbra={0.5}
              intensity={1}
              castShadow
            />
            <Controllers />
            <Hands />
            <DefaultHandControllers />
            <FPSCounter />
            <OrbitControls />
            <Scene />
            <Preload all />
            <Portals />
          </Suspense>
        </XR>
      </Canvas>
    </>
  );
}