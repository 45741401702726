import { Text } from "@react-three/drei";
import { useStore } from "utils/store";
import usePlayerWithDev from "hooks/usePlayerWithDev";

function PlayerMovementInfo() {
  console.log("🌺🌺🌺🌺🌺 RE-RENDERING PlayerMovementInfo")
  const { player } = usePlayerWithDev();
  const isMovementRestricted = useStore((store) => store.isMovementRestricted);
  const parseTwoDecimalPlaces = (val: number) => val.toFixed(4);

  return (
    <>
      <Text
        position={[
          player.position.x,
          player.position.y + 4,
          player.position.z - 5,
        ]}
        fontSize={0.5}
        color="#C70039"
      >
        {`Player Position (Handtracking): ${parseTwoDecimalPlaces(
          player.position.x
        )}, ${parseTwoDecimalPlaces(
          player.position.y
        )}, ${parseTwoDecimalPlaces(player.position.z)}`}
      </Text>

      {isMovementRestricted && (
        <Text
          position={[
            player.position.x,
            player.position.y + 1,
            player.position.z - 5,
          ]}
          fontSize={0.8}
          color="blue"
        >
          Nothing to show ... Move back!
        </Text>
      )}
    </>
  );
}

export default PlayerMovementInfo;
