import { Link } from "react-router-dom";

export default function FinalHome() {
  const links = [
    {
      label: "Tokyo Night (JPG)",
      link: "/final/tokyo-night",
      priority: 2,
      description: "Tokyo Night scene. No depthmap applied. Nothing else. Assets coming from Frontend.",
      beforeVRMemory: 31.2,
      afterVRMemory: 32.6
    },
    {
      label: "Tokyo Night with Depthmap (JPG)",
      link: "/final/tokyo-night-with-depthmap",
      priority: 2,
      description: "Tokyo Night with added depth mapping. Assets coming from Frontend.",
      beforeVRMemory: 46.6,
      afterVRMemory: 46.7
    },
    {
      label: "Mountain Beach (JPG)",
      link: "/final/mountain-beach",
      priority: 2,
      description: "Beach with Mountains scene. No depthmap applied. Nothing else. Assets coming from Frontend.",
      beforeVRMemory: 31.4,
      afterVRMemory: 31.5
    },
    {
      label: "Mountain Beach with Depthmap (JPG)",
      link: "/final/mountain-beach-with-depthmap",
      priority: 2,
      description: "Mountain Beach scene with added depth mapping. Assets coming from Frontend.",
      beforeVRMemory: 31.9,
      afterVRMemory: 32.0
    },
    {
      label: "Arturo replaced with Tokyo Night (WEBP)",
      link: "/final/arturo-replaced-with-tokyo-night",
      priority: 2,
      description: "Rendering only webp image of Arturo standing outside instead of Tokyo Night. Assets coming from Frontend.",
      beforeVRMemory: 28.2,
      afterVRMemory: 28.2
    },
    {
      label: "Arturo replaced with Tokyo Night + Added Depthmap (WEBP)",
      link: "/final/arturo-replaced-with-tokyo-night-with-depthmap",
      priority: 2,
      description: "Rendering webp images for actual image & depthmap of Arturo standing outside. Assets coming from Frontend.",
      beforeVRMemory: 31.9,
      afterVRMemory: 32.0
    },
    {
      label: "Arturo replaced with Tokyo Night (PNG)",
      link: "/final/arturo-replaced-with-tokyo-night-png",
      priority: 2,
      description: "Rendering PNG image of Arturo standing outside instead of Tokyo Night. Assets coming from Frontend.",
      beforeVRMemory: 28.6,
      afterVRMemory: 28.8
    },
    {
      label: "Arturo replaced with Tokyo Night (PNG) + Added Depthmap",
      link: "/final/arturo-replaced-with-tokyo-night-with-depthmap-png",
      priority: 2,
      description: "Rendering PNG images for actual image & depthmap of Arturo standing outside. Assets coming from Frontend.",
      beforeVRMemory: 31.1,
      afterVRMemory: 31.1
    },
    {
      label: "Arturo 3d Model (WEBP)",
      link: "/final/arturo-3d-model-webp",
      priority: 2,
      description: "Rendering 3D model having WEBP Texture. Assets coming from Frontend.",
      beforeVRMemory: 32.5,
      afterVRMemory: 32.6
    },
    {
      label: "Arturo 1 Segment replaced with Tokyo Night (WEBP)",
      link: "/final/arturo-one-segment-3d-model-webp",
      priority: 2,
      description: "Rendering 1 Segment WEBP Texture instead of Tokyo Night Scene. Assets coming from Frontend.",
      beforeVRMemory: 31.3,
      afterVRMemory: 32.1
    },
    {
      label: "Webp Datapoints with hand tracking",
      link: "/final/webp-datapoints-with-hand-tracking",
      priority: 2,
      description: "Rendering multiple in Arturo standing outside as webp. Assets coming from Frontend.",
      beforeVRMemory: 31.8,
      afterVRMemory: 33.5
    },
    {
      label: "Webp Datapoints with hand tracking with depthmap",
      link: "/final/webp-datapoints-with-hand-tracking-with-depthmap",
      priority: 2,
      description: "Rendering multiple datapoints both High Resoultion & Depthmap in Arturo standing outside as webp. Assets coming from Frontend.",
      beforeVRMemory: 30.2,
      afterVRMemory: 33.7
    },
    {
      label: "(Default Shader) Webp segment with Default Shader + Patched UV segment finding logic",
      link: "/final/webp-segment-default-shader",
      priority: 2,
      description: "Rendering only 1 Webp Segment at a time using Default Threejs Shader Material. Patched UV segment finding logic. Assets coming from Frontend.",
      beforeVRMemory: 31.2,
      afterVRMemory: 32.3
    },
    {
      label: "(Custom Shader) Webp segment with Custom Shader + Patched UV segment finding logic",
      link: "/final/webp-segment-custom-shader",
      priority: 2,
      description: "Rendering only 1 Webp Segment at a time using Custom Shader. Patched UV segment finding logic. Assets coming from Frontend.",
      beforeVRMemory: 28.5,
      afterVRMemory: 29.5
    },
    {
      label: "Webp segment only one at a time + Default Shader + Data Preloading",
      link: "/final/webp-segment-default-shader-with-data-preloading",
      priority: 2,
      description: "Rendering only 1 Webp Segment at a time using Default Shader. Pre-loading all of the assets first. Assets coming from Frontend.",
      beforeVRMemory: 32.0,
      afterVRMemory: 33.0
    },
    {
      label: "Webp segment only one at a time + Custom Shader + Data Preloading + Low Resolution WEBP applied",
      link: "/final/webp-segment-custom-shader-with-data-preloading",
      priority: 2,
      description: "Rendering only 1 Webp Segment at a time using Custom Shader. Pre-loading all of the assets first. Low resolution WEBP image is also applied. Assets coming from Frontend.",
      beforeVRMemory: 29.6,
      afterVRMemory: 30.5
    },
    {
      label: "High res segment overlayed over low res image by default using Editing Software (Adobe Photoshop)",
      link: "/final/high-res-segment-overlayed-over-low-res-image-by-default",
      priority: 2,
      description: "High res image is already overlayed over low res image with Photoshop by extending 4k to 8k. Assets coming from Frontend.",
      beforeVRMemory: 31.2,
      afterVRMemory: 31.9
    },
    {
      label: "Complete System with Segments Technology",
      link: "/final/complete-system-with-segments-technology",
      priority: 2,
      description: "Rendering Complete System with technologies like segments, webp, high res, low res, streaming, neo4j like everything we have done so far.  Assets coming from S3 + Backend.",
      beforeVRMemory: 29.3,
      afterVRMemory: 30.5
    },
  ];

  links.sort((a, b) => a.priority - b.priority);

  return (
    <div className="w-screen py-20 bg-blue-950 flex flex-col justify-center items-center gap-12">
      <h1 className="text-6xl font-extrabold text-white mb-10">Variations of 360 VR Environment</h1>

      <div className="w-full max-w-[90%] overflow-x-auto rounded-lg shadow-lg">
        <table className="min-w-full divide-y divide-teal-200">
          <thead className="bg-blue-900 rounded-t-lg">
            <tr>
              <th
                scope="col"
                className="max-w-[600px] px-6 py-3 text-left text-lg font-medium text-teal-200 uppercase tracking-wider"
              >
              </th>
              <th
                scope="col"
                className="max-w-[600px] px-6 py-3 text-left text-lg font-medium text-teal-200 uppercase tracking-wider"
              >
                Environment
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-lg font-medium text-teal-200 uppercase tracking-wider"
              >
                Description
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-lg font-medium text-teal-200 uppercase tracking-wider"
              >
                Memory
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center text-lg font-medium text-teal-200 uppercase tracking-wider"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody className="bg-slate-700 divide-y divide-teal-200 rounded-b-lg">
            {links.map(({ link, label, description, beforeVRMemory, afterVRMemory }, index) => (
              <tr key={index}>
                <td className="px-6 py-4 border-r border-teal-200">
                  <div className="text-lg font-medium text-teal-200">{index + 1}</div>
                </td>
                <td className="max-w-[600px] px-6 py-4 border-r border-teal-200">
                  <div className="text-lg font-medium text-teal-200">{label}</div>
                </td>
                <td className="px-6 py-4 whitespace-normal border-r border-teal-200">
                  <div className="text-lg text-teal-200">{description}</div>
                </td>
                <td className="px-6 py-4 whitespace-normal border-r border-teal-200">
                  <pre className="text-lg text-teal-200">{`Before VR: ${beforeVRMemory}mb\nAfter Entering VR: ${afterVRMemory}mb`}</pre>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-center">
                  <Link
                    to={link}
                    className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-blue-900 bg-teal-200 hover:bg-teal-300"
                  >
                    View
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}