import { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { Text } from "@react-three/drei";
import usePlayerWithDev from "hooks/usePlayerWithDev";

export default function FPSCounter() {
  const textRef = useRef(null);
  const lastFrameTime = useRef(performance.now());
  const { player } = usePlayerWithDev();

  useFrame(() => {
    const now = performance.now();
    const deltaTime = now - lastFrameTime.current;
    lastFrameTime.current = now;
    const newFps = 1 / (deltaTime / 1000);

    if (textRef.current) {
      textRef.current.text = `FPS: ${newFps.toFixed(2)}`;
    }
  });

  const position = [
    player.position.x,
    player.position.y + 2,
    player.position.z - 5,
  ];

  return (
    <Text ref={textRef} position={position as any} fontSize={0.5} color="white">
      FPS: 0
    </Text>
  );
}
