import { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { XR, Controllers, Hands, VRButton } from "@react-three/xr";
import { OrbitControls } from "@react-three/drei";
import { Preload } from "@react-three/drei";
import DefaultHandControllers from "components/HandTracking/DefaultHandControllers";
import FPSCounter from "components/FPSCounter";
import Loader from "components/Loader";
import { useState } from "react";
import { Html, Text } from "@react-three/drei";
import { useLoader, useFrame } from "@react-three/fiber";
import { useXR } from "@react-three/xr";
import * as THREE from "three";
import { useStore } from "utils/store";
import { Vector3 } from "three";

function Scene() {
  const [viewerPosition, setViewerPosition] = useState(new Vector3());
  const { player, session, referenceSpace } = useXR();

  useFrame((state, delta) => {
    if (!session) return;

    session.requestReferenceSpace(referenceSpace).then((refSpace: any) => {
      session.requestAnimationFrame((timestamp, xrFrame) => {
        const pose = xrFrame.getViewerPose(refSpace);

        const { x, y, z } = pose.transform.position;

        setViewerPosition(new Vector3(x, y, z));
      });
    });
  });

  return (
    <>
      <Text
        position={[
          player.position.x,
          player.position.y + 3,
          player.position.z - 5,
        ]}
        fontSize={0.5}
        color="#FF6581"
      >
        {`Position: ${viewerPosition.x}, ${viewerPosition.y}, ${viewerPosition.z}`}
      </Text>

      <Text
        position={[
          player.position.x,
          player.position.y + 4,
          player.position.z - 5,
        ]}
        fontSize={0.5}
        color="#62FF33"
      >
        {`Player Position: ${player.position.x}, ${player.position.y}, ${player.position.z}`}
      </Text>
    </>
  );
}

function Experience({ name, position, texture, onClick }) {
  const [hover, setHover] = useState(false);

  return (
    <group>
      <mesh rotation={[0, Math.PI / 2, 0]}>
        <sphereGeometry args={[500, 60, 40]} />
        <meshStandardMaterial map={texture} side={THREE.BackSide} />
      </mesh>

      <mesh position={position}>
        <sphereGeometry args={[1.25, 32, 32]} />
        <meshBasicMaterial color={hover ? "red" : "blue"} />
        <Html center>
          <a onClick={() => onClick(name)} style={{ cursor: "pointer" }}>
            {name}
          </a>
        </Html>
      </mesh>
    </group>
  );
}

function Portals() {
  const gap = 20;
  const [environmentList, setEnvironmentList] = useState([
    {
      name: "1",
      color: "lightblue",
      position: [2, 1.7, 1 * gap],
      url: "/images/arturo-standing-datapoints-webp-images/0001.webp",
    },
    {
      name: "2",
      color: "lightblue",
      position: [2, 1.7, 2 * gap],
      url: "/images/arturo-standing-datapoints-webp-images/0002.webp",
    },
    {
      name: "3",
      color: "lightblue",
      position: [2, 1.7, 3 * gap],
      url: "/images/arturo-standing-datapoints-webp-images/0003.webp",
    },
    {
      name: "4",
      color: "lightblue",
      position: [2, 1.7, 4 * gap],
      url: "/images/arturo-standing-datapoints-webp-images/0004.webp",
    },
    {
      name: "5",
      color: "lightblue",
      position: [2, 1.7, 5 * gap],
      url: "/images/arturo-standing-datapoints-webp-images/0005.webp",
    },
    {
      name: "6",
      color: "lightblue",
      position: [2, 1.7, 6 * gap],
      url: "/images/arturo-standing-datapoints-webp-images/0006.webp",
    },
    {
      name: "7",
      color: "lightblue",
      position: [2, 1.7, 7 * gap],
      url: "/images/arturo-standing-datapoints-webp-images/0007.webp",
    },
    {
      name: "8",
      color: "lightblue",
      position: [2, 1.7, 8 * gap],
      url: "/images/arturo-standing-datapoints-webp-images/0008.webp",
    },
  ]);

  const [current, setCurrent] = useState(2);
  const textures = useLoader(
    THREE.TextureLoader,
    environmentList.map((entry) => entry.url)
  );
  const isMoveGestureDetected = useStore(
    (store) => store.isMoveGestureDetected
  );
  const { player, isHandTracking } = useXR();
  const [isMovementRestricted, setIsMovementRestricted] = useState(false);

  const handleClick = (name) => {
    if (isMoveGestureDetected) {
      const clickedIndex = environmentList.findIndex(
        (item) => item.name === name
      );

      if (clickedIndex !== -1) {
        setCurrent(clickedIndex);
      }
    }
  };

  useFrame((state, delta) => {
    let distancesList = [];
    for (let index = 0; index < environmentList.length; index++) {
      const element = environmentList[index];

      if (index !== current) {
        const distance = player.position.distanceTo(
          new THREE.Vector3(
            element.position[0],
            element.position[1],
            element.position[2]
          )
        );
        distancesList.push({
          name: element.name,
          distance,
        });

        if (distance < 5) {
          setCurrent(index);
        }
      }
    }
    console.table(distancesList);
  });

  return (
    <>
      {environmentList.map(
        (item, index) =>
          current !== index && (
            <Experience
              key={item.name}
              onClick={handleClick}
              name={item.name}
              position={item.position}
              texture={textures[current]}
            />
          )
      )}

      {isMovementRestricted && (
        <Text
          position={[
            player.position.x,
            player.position.y + 1,
            player.position.z - 5,
          ]}
          fontSize={0.8}
          color="blue"
        >
          Nothing to show ... Move back!
        </Text>
      )}
    </>
  );
}

export default function WebpDatapointsWithHandTracking() {
  return (
    <>
      <VRButton />

      <Canvas>
        <XR referenceSpace="local-floor">
          <Suspense fallback={<Loader />}>
            <ambientLight intensity={2} />

            <spotLight
              position={[0, 20, 10]}
              angle={0.3}
              penumbra={0.5}
              intensity={1}
              castShadow
            />

            <Controllers />
            <Hands />
            <DefaultHandControllers />
            <FPSCounter />

            <OrbitControls />
            <Scene />

            <Preload all />
            <Portals />
          </Suspense>
        </XR>
      </Canvas>
    </>
  );
}
