import { useRef } from "react";
import { Canvas } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
import { XR, Controllers, Hands, VRButton } from "@react-three/xr";
import { OrbitControls } from "@react-three/drei";

function HighResWebpSphereModel(props) {
  const { nodes, materials } = useGLTF(
    "/models/high-res-webp-sphere.glb"
  );

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={(nodes as any).Sphere.geometry}
        scale={10}
        material={materials["Material.002"]}
      />
    </group>
  );
}

// useGLTF.preload("/models/high-res-webp-sphere.glb");

export default function Arturo3DModelWebp() {
  const sphereRef = useRef();

  return (
    <>
      <VRButton />
      <Canvas>
        <XR referenceSpace="local-floor">
          <ambientLight intensity={2} />
          <HighResWebpSphereModel ref={sphereRef} />
          <Controllers />
          <Hands />
          <OrbitControls />
        </XR>
      </Canvas>
    </>
  );
}
