import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { XRController, useXR, useTeleportation } from "@react-three/xr";
import { Object3D, Vector3 } from "three";
import { HandModel } from "components/HandTracking/HandModel";
import { useStore } from "utils/store";
import { useEffect, useRef } from "react";
import useWebSocket from "./useWebSocket";

interface Props {
  controller: XRController;
  model: HandModel;
  distanceThreshold?: Number;
  labelLength?: Number;
}

function useMovementGesture({ model, distanceThreshold = 0.035 }: Props) {
  const { camera } = useThree();
  const { isHandTracking, isPresenting, player, session, referenceSpace } =
    useXR();
  const teleport = useTeleportation();
  const set = useStore((state) => state.set);
  // const isMoveGestureDetected = useStore(
  //   (store) => store.isMoveGestureDetected
  // );

  // const posePosition = useRef<Vector3>(null);
  const xrRefSpace = useRef<XRReferenceSpace | null>(null);
  const headsetPosition = useRef<Vector3>(new Vector3());

  const { handleUserConnectedEmitEvent, handleCoordinatesEmitEvent } =
    useWebSocket();

  /**
   * Request and cache XR reference space once.
   */
  useEffect(() => {
    if (session && referenceSpace) {
      session
        .requestReferenceSpace(referenceSpace)
        .then((refSpace: XRReferenceSpace) => {
          xrRefSpace.current = refSpace;
        });
    }
  }, [session, referenceSpace]);

  useEffect(() => {
    handleUserConnectedEmitEvent(player.position);
  }, []);

  useFrame(() => {
    if (!isHandTracking || !isPresenting) return;

    if (!model || model?.bones.length === 0) {
      return;
    }

    if (!headsetPosition.current) {
      headsetPosition.current.copy(player.position);
    }

    const wrist = model.bones.find(
      (bone) => (bone as any).jointName === "wrist"
    );

    const { distancesMessage, allDistancesBelowThreshold } =
      isDistanceFulfilled([
        "index-finger-phalanx-intermediate",
        "middle-finger-phalanx-intermediate",
        "ring-finger-phalanx-intermediate",
        "pinky-finger-phalanx-intermediate",
      ]);
    const isWristParallel = isWristParallelToVerticalAxis();
    const areTipsHigherThanDistal = checkIfTipsAreHigherThanDistal();

    const { position, direction } = getLinePositionAndDirectionFromBone(wrist);

    const directionVector = new THREE.Vector3(direction.x, 0, direction.z)
      .normalize()
      .negate();

    let isMoveGestureDetected = false;
    if (
      allDistancesBelowThreshold &&
      isWristParallel &&
      areTipsHigherThanDistal
    ) {
      const wristDirection = getPerndicularVector("wrist");
      wristDirection.y = 0;

      const playerDirection = new THREE.Vector3();
      player.getWorldDirection(playerDirection);
      const movementSpeed = 0.1;

      if (playerDirection.z > 0) {
        player.position.add(directionVector.multiplyScalar(movementSpeed));
        // teleport(
        //   player.position.add(directionVector.multiplyScalar(movementSpeed))
        // );
      } else if (playerDirection.z < 0) {
        player.position.add(directionVector.multiplyScalar(movementSpeed * -1));
        // teleport(
        //   player.position.add(
        //     directionVector.multiplyScalar(movementSpeed * -1)
        //   )
        // );
      }

      isMoveGestureDetected = true;

      // console.log("🔥🔥🔥 player position: ", player.position);
    }

    /**
     * Updating the state
     */
    set((state: any) => {
      state.isMoveGestureDetected = isMoveGestureDetected;
    });

    // if (!session) return;

    if (isPresenting && session && xrRefSpace.current) {
      session.requestAnimationFrame((timestamp, xrFrame) => {
        const pose = xrFrame.getViewerPose(xrRefSpace.current!);

        const { x, y, z } = pose.transform.position;
        const newPosePosition = new Vector3(x, y, z);

        if (!isMoveGestureDetected) {
          // const delta = newPosePosition.sub(headsetPosition.current);
          const delta = newPosePosition.clone().sub(headsetPosition.current);
          // console.log("isMoveGestureDetected: ", isMoveGestureDetected);

          player.position.copy(player.position.add(delta));
        }

        // posePosition.current = newPosePosition;
        headsetPosition.current.copy(newPosePosition);
      });
    }

    // console.log('player position: ', player.position);
    handleCoordinatesEmitEvent(player.position);
  });

  /**
   * Get Line Position and Direction from Bone
   * @param bone
   */
  function getLinePositionAndDirectionFromBone(bone: THREE.Object3D) {
    // Local direction is along the y-axis
    const localDirection = new THREE.Vector3(0, 1, 0).normalize(); // Direction vector in bone's local space

    // The position of the bone in world coordinates
    const position = new THREE.Vector3();
    bone.getWorldPosition(position);

    // Get the world quaternion of the bone
    const worldQuaternion = new THREE.Quaternion();
    bone.getWorldQuaternion(worldQuaternion);

    // Use the world quaternion to rotate the local direction vector to world space
    const direction = localDirection.applyQuaternion(worldQuaternion);

    return { position, direction };
  }

  /**
   * Calculate distance between two points
   * Get the position for each joint name in jointList
   * Calculate distances between 2 joints in sequential order using calculateSequentialDistances method
   * Check if the values are less than the this.data.distanceThreshold
   * Show this values in debugging-window a-text element
   */
  function isDistanceFulfilled(jointList) {
    let jointPositionList = [];
    jointList.forEach((jointName) => {
      const joint = getJointInfo(jointName);
      jointPositionList.push(joint.position);
    });
    const jointDistances = calculateSequentialDistances(jointPositionList);
    const isDistanceWithinThreshold = Object.values(jointDistances);

    const allDistancesBelowThreshold = isDistanceWithinThreshold.every(
      (distance) => distance < distanceThreshold
    );

    const distancesMessage = Object.keys(jointDistances)
      .map((key) => `${key}: ${jointDistances[key].toFixed(2)}`)
      .join("\n");

    return {
      distancesMessage,
      allDistancesBelowThreshold,
    };
  }

  /**
   * Get perndicular vector
   * @param {Array<string>} jointName
   */
  function getPerndicularVector(jointName) {
    const jointDirection = getWristDirection();

    // Assuming jointDirection is a THREE.Vector3 representing the direction of the joint
    // Choose a reference vector. For example, the world up vector.
    const referenceVector = new THREE.Vector3(0, 1, 0); // World up vector

    // Calculate the cross product to get a perpendicular (normal) vector
    let normalVector = jointDirection
      .clone()
      .cross(referenceVector)
      .normalize();

    // If the result is a zero vector (i.e., jointDirection was parallel to the reference vector), try a different reference
    if (normalVector.lengthSq() === 0) {
      // Use an alternative reference vector, for example, the world forward vector
      const alternativeReference = new THREE.Vector3(0, 0, 1);
      normalVector = new THREE.Vector3(); // Reset normalVector to be a new vector
      normalVector
        .crossVectors(jointDirection, alternativeReference)
        .normalize();
    }

    return normalVector; // Return the normal (perpendicular) vector
  }

  /**
   * Get Wrist Direction
   * @returns {boolean}
   */
  function getWristDirection() {
    const wrist = model.bones.find(
      (bone) => (bone as any).jointName === "wrist"
    ) as Object3D;
    const wristDirection = wrist
      .getWorldDirection(new Vector3())
      .negate()
      .multiplyScalar(20);

    return wristDirection;
  }

  /**
   * Checks if the wrist is parallel to the vertical axis.
   * @returns {boolean}
   */
  function isWristParallelToVerticalAxis() {
    const wristDirection = getWristDirection();

    const verticalAxis = new THREE.Vector3(0, 1, 0);
    const dotProduct = wristDirection.dot(verticalAxis);
    const isParallel = dotProduct > 18 && dotProduct < 20;

    return isParallel;
  }

  /**
   * Are Tips Higher Than Distal
   * @returns {boolean}
   */
  function checkIfTipsAreHigherThanDistal() {
    const tipJointNames = [
      "index-finger-tip",
      "middle-finger-tip",
      "ring-finger-tip",
      "pinky-finger-tip",
    ];
    const tipPositions = tipJointNames.map(
      (jointName) => getJointInfo(jointName).position
    );

    const distalJointNames = [
      "index-finger-phalanx-distal",
      "middle-finger-phalanx-distal",
      "ring-finger-phalanx-distal",
      "pinky-finger-phalanx-distal",
    ];
    const distalPositions = distalJointNames.map(
      (jointName) => getJointInfo(jointName).position
    );

    for (let i = 0; i < tipPositions.length; i++) {
      if (tipPositions[i].y <= distalPositions[i].y) {
        return false;
      }
    }

    return true;
  }

  /**
   * Calculate distance between points list
   * @param {Array<string>} points
   */
  function calculateSequentialDistances(points: any) {
    let distances = {};
    for (let i = 0; i < points.length - 1; i++) {
      const distance = calculateDistance(points[i], points[i + 1]);
      distances[`Distance_${i + 1}_${i + 2}`] = distance;
    }
    return distances;
  }

  /**
   * Calculate distance between two points
   * @param {Array<string>} points
   */
  function calculateDistance(vec1: any, vec2: any) {
    return vec1.distanceTo(vec2); // Utilizes THREE.Vector3's distanceTo method
  }

  /**
   * Check Is Wrist Pendicular Parallel to Camera Forward Vector
   */
  function getCameraWorldDirection() {
    return camera.getWorldDirection(new THREE.Vector3());
  }

  /**
   * This function is going to be used to log the position, direction, normal & quaternion
   * information for the given joint name.
   * @param {string} jointName
   */
  function getJointInfo(jointName) {
    const joint = model!.bones.find(
      (bone) => (bone as any).jointName === jointName
    )! as Object3D;

    let jointInfo = {
      position: null,
      direction: null,
      normal: null,
      quaternion: null,
    };

    if (joint) {
      jointInfo.position = joint.getWorldPosition(new THREE.Vector3());
      jointInfo.direction = joint.getWorldPosition(new THREE.Vector3());
      jointInfo.normal = joint.getWorldDirection(new THREE.Vector3());
      jointInfo.quaternion = joint.getWorldQuaternion(new THREE.Quaternion());
    }

    return jointInfo;
  }

  return null;
}

export default useMovementGesture;
