/* eslint-disable react-hooks/rules-of-hooks */
import { useRef, useState, useEffect, useMemo } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";
import useWebSocket, { EventTypes } from "hooks/useWebSocket";
import { StreamSegmentConfigType } from "types";
import { DataPointType } from "utils/types";

interface UseEnvironmentOuterGlobeParams {
  reference: React.MutableRefObject<THREE.Mesh | undefined> | any;
  intersectedGlobe: React.MutableRefObject<
    THREE.Intersection<THREE.Object3D<THREE.Object3DEventMap>> | undefined
  >;
  lowResImage: string;
  depthmapImage: string;
  userGettingAwayRef: React.MutableRefObject<boolean>;
  dataPointList: DataPointType[];
  currentDatapointIndex: number;
}

function useTextureFromArrayBuffer(arrayBuffer, mimeType = "image/png") {
  const blobUrlRef = useRef(null);
  const [texture, setTexture] = useState(null);
  const textureLoader = useMemo(() => new THREE.TextureLoader(), []);

  useEffect(() => {
    if (!arrayBuffer) return;

    // Clean up the old blob URL if it exists.
    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current);
    }

    // Create a new blob URL only when arrayBuffer changes.
    const blob = new Blob([arrayBuffer], { type: mimeType });
    const blobUrl = URL.createObjectURL(blob);
    blobUrlRef.current = blobUrl;

    // Use TextureLoader directly.
    textureLoader.load(blobUrl, (loadedTexture) => {
      setTexture(loadedTexture);
    });

    return () => {
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
        blobUrlRef.current = null;
      }
    };
  }, [arrayBuffer, mimeType]);

  return texture;
}

export default function useEnvironmentOuterGlobe({
  reference,
  intersectedGlobe,
  lowResImage,
  depthmapImage,
  userGettingAwayRef,
  dataPointList,
  currentDatapointIndex,
}: UseEnvironmentOuterGlobeParams) {
  const uThreshold = 30.0;
  const highResScale = 1.0;
  const { socket, publishGlobeIntersectionPoint } = useWebSocket();
  const [texturesLoaded, setTexturesLoaded] = useState(false);

  const lastSegmentImageBase64 = useRef<ArrayBuffer>(null);
  const [segmentTexture, setSegmentTexture] = useState<THREE.Texture | null>(null);

  const dataPoint = dataPointList[currentDatapointIndex];
  const dataPointId = dataPoint?.datapointId;

  const lowResTexture = useTextureFromArrayBuffer(lowResImage, "image/png");
  const depthTexture = useTextureFromArrayBuffer(depthmapImage, "image/png");

  const uvCoordinateRef = useRef<{ u: number; v: number } | null>(null);
  const faceARef = useRef(new THREE.Vector3());
  const faceBRef = useRef(new THREE.Vector3());
  const faceCRef = useRef(new THREE.Vector3());

  const textureLoader = useMemo(() => new THREE.TextureLoader(), []);

  useEffect(() => {
    console.log('🌺 segmentTexture: ', segmentTexture)
  }, [segmentTexture])

  useEffect(() => {
    if (!socket) return;

    const handleStreamSegment = (data: StreamSegmentConfigType) => {
      if (lastSegmentImageBase64.current === data.imageBuffer) {
        return;
      }

      console.log('✅ data.imageBuffer: ', data.imageBuffer)
      const blob = new Blob([data.imageBuffer], { type: "image/png" });
      const blobUrl = URL.createObjectURL(blob);
      lastSegmentImageBase64.current = data.imageBuffer;
  
      // Use TextureLoader directly.
      textureLoader.load(blobUrl, (loadedTexture) => {
        console.log('         🤓              loadedTexture: ', loadedTexture)
        setSegmentTexture(loadedTexture);
      });
      console.log("🔳 Sync Streaming Data: ", data);
    };

    socket.on(EventTypes.STREAM_SEGMENT_IMAGE, handleStreamSegment);

    return () => {
      if (socket) {
        socket.emit(EventTypes.DISCONNECT);
        socket.disconnect();
      }
    };
  }, [socket]);

  // === Set texture filters once loaded ===
  useEffect(() => {
    if (lowResTexture && depthTexture) {
      setTexturesLoaded(true);
      // lowResTexture.minFilter = THREE.NearestFilter;
      // lowResTexture.generateMipmaps = false;

      // depthTexture.minFilter = THREE.NearestFilter;
      // depthTexture.generateMipmaps = false;
    }
  }, [lowResTexture, depthTexture]);

  const vertexShader = `
    varying vec3 vPosition;
    varying vec2 vUv;
    varying float vDistance;
    uniform int uIntersectedFaceIndex;
    uniform vec3 uFaceA;
    uniform vec3 uFaceB;
    uniform vec3 uFaceC;
    uniform float uThreshold;
    uniform sampler2D depthMap;
    uniform float displacementScale;
    uniform float minDepth;
    uniform float maxDepth;

    vec3 calculateCentroid(vec3 a, vec3 b, vec3 c) {
      return (a + b + c) / 3.0;
    }

    void main() {
      vUv = uv;
      vPosition = position;

      vec4 depthData = texture2D(depthMap, uv);
      float vDisplacement = depthData.r * displacementScale;
      vec3 transformedPosition = position + normal * vDisplacement;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(transformedPosition, 1.0);

      if (uIntersectedFaceIndex >= 0) {
        vec3 faceTriangleCentroid = calculateCentroid(uFaceA, uFaceB, uFaceC);
        vDistance = length(vPosition - faceTriangleCentroid);
      } else {
        vDistance = 1.0;
      }
    }
  `;

  const fragmentShader = `
    varying vec2 vUv;
    varying vec3 vPosition;
    varying float vDistance;
    uniform sampler2D uLowResTexture;
    uniform sampler2D uHighResTexture;
    uniform int uIntersectedFaceIndex;
    uniform float uThreshold;
    uniform float minDepth;
    uniform float maxDepth;
    uniform bool userGettingAway;
    uniform float highResScale;

    void main() {
      vec3 color = vec3(0.0);

      // if (!userGettingAway) {
      //   color = texture2D(uLowResTexture, vUv).rgb;
      //   vec2 scaledUv = vUv * highResScale;
      //   vec4 highResColor = texture2D(uHighResTexture, scaledUv);
      //   if (highResColor.a > 0.0) {
      //     color = highResColor.rgb;
      //   }
      // }

      color = texture2D(uLowResTexture, vUv).rgb;
      vec2 scaledUv = vUv * highResScale;
      vec4 highResColor = texture2D(uHighResTexture, scaledUv);
      if (highResColor.a > 0.0) {
        color = highResColor.rgb;
      }

      float circleRadius = 2.0;
      if (vDistance < circleRadius && uIntersectedFaceIndex >= 0) {
        color = vec3(255.0, 255.0, 255.0);
      }

      gl_FragColor = vec4(color, 1.0);
    }
  `;

  // === Intersection logic for the globe ===
  useFrame(() => {
    if (!reference.current) return;
    if (!intersectedGlobe.current || intersectedGlobe.current.faceIndex < 0)
      return;

    // Directly use numeric UV values (avoiding JSON.stringify)
    const { width: u, height: v } = intersectedGlobe.current.uv;
    if (!uvCoordinateRef.current) {
      uvCoordinateRef.current = { u, v };
      console.log("Initial uv: ", uvCoordinateRef.current);
    } else if (
      Math.abs(uvCoordinateRef.current.u - u) > 0.0001 ||
      Math.abs(uvCoordinateRef.current.v - v) > 0.0001
    ) {
      publishGlobeIntersectionPoint(u, v, dataPointId, null, null);
      uvCoordinateRef.current.u = u;
      uvCoordinateRef.current.v = v;
    }

    const geometry = reference.current.geometry;
    const faceIndex = intersectedGlobe.current.faceIndex;

    // Determine the indices for the intersected face.
    let faceIndices: number[];
    if (geometry.index) {
      const arr = geometry.index.array;
      faceIndices = [
        arr[faceIndex * 3],
        arr[faceIndex * 3 + 1],
        arr[faceIndex * 3 + 2],
      ];
    } else {
      faceIndices = [faceIndex * 3, faceIndex * 3 + 1, faceIndex * 3 + 2];
    }

    const positions = geometry.attributes.position.array as Float32Array;
    // Update pre-cached vectors in place instead of allocating new ones.
    faceARef.current.set(
      positions[faceIndices[0] * 3],
      positions[faceIndices[0] * 3 + 1],
      positions[faceIndices[0] * 3 + 2]
    );
    faceBRef.current.set(
      positions[faceIndices[1] * 3],
      positions[faceIndices[1] * 3 + 1],
      positions[faceIndices[1] * 3 + 2]
    );
    faceCRef.current.set(
      positions[faceIndices[2] * 3],
      positions[faceIndices[2] * 3 + 1],
      positions[faceIndices[2] * 3 + 2]
    );

    // Update shader uniforms without reassigning new objects
    const uniforms = reference.current.material.uniforms;
    uniforms.uFaceA.value.copy(faceARef.current);
    uniforms.uFaceB.value.copy(faceBRef.current);
    uniforms.uFaceC.value.copy(faceCRef.current);
    uniforms.uIntersectedFaceIndex.value = faceIndex;
  });

  // === Return memoized uniforms and shaders ===
  const uniforms = useMemo(() => ({
    uLowResTexture: { value: lowResTexture || new THREE.Texture() },
    uHighResTexture: { value: segmentTexture || new THREE.Texture() },
    depthMap: { value: depthTexture || new THREE.Texture() },
    displacementScale: { value: 20 },
    uIntersectedFaceIndex: { value: -1 },
    uFaceA: { value: new THREE.Vector3() },
    uFaceB: { value: new THREE.Vector3() },
    uFaceC: { value: new THREE.Vector3() },
    uThreshold: { value: uThreshold },
    minDepth: { value: 50.0 },
    maxDepth: { value: 140.0 },
    userGettingAway: { value: userGettingAwayRef.current },
    highResScale: { value: highResScale },
  }), [
    lowResTexture,
    segmentTexture,
    depthTexture,
    uThreshold,
    highResScale,
    userGettingAwayRef,
  ]);

  return {
    texturesLoaded,
    vertexShader,
    fragmentShader,
    uniforms,
  };
}
