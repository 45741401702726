/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 public/models/high-res-webp-sphere.glb --transform 
Files: public/models/high-res-webp-sphere.glb [2.07MB] > /Users/hamari_m1/Projects/Freelance-Projects/Upwork/Arturo/truevue-frontend/high-res-webp-sphere-transformed.glb [89.63KB] (96%)
*/

import React from "react";
import { useGLTF } from "@react-three/drei";

export default function HighResWebpSphereModel(props) {
  const { nodes, materials } = useGLTF(
    // "/models/high-res-webp-sphere-transformed.glb"
    // "/models/high-res-webp-sphere-large-transformed.glb"
    "/models/high-res-webp-sphere.glb"
  );
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Sphere.geometry}
        scale={10}
        material={materials["Material.002"]}
      />
    </group>
  );
}

// useGLTF.preload("/models/high-res-webp-sphere.glb");
