import { useRef, useEffect, useState } from "react";
import { Canvas, useLoader } from "@react-three/fiber";
import { useXR, VRButton, XR } from "@react-three/xr";
import * as THREE from "three";
import { TextureLoader } from "three";
import { OrbitControls } from "@react-three/drei";

function Panorama() {
  const sphereRef = useRef<any>();
  const { isPresenting } = useXR();
  const [texturesLoaded, setTexturesLoaded] = useState(false);

  const [texture] = useLoader(TextureLoader, [
    "/images/output_frame_8K.jpg",
  ]);

  useEffect(() => {
    if (texture) {
      setTexturesLoaded(true);
      texture.minFilter = THREE.NearestFilter;
      texture.generateMipmaps = false;
    }
  }, [texture]);

  if (!texturesLoaded) return null;

  return (
    <mesh ref={sphereRef} position={[0, isPresenting ? 2.6 : 0, 0]}>
      <sphereGeometry args={[6, 250, 256]} />
      <meshStandardMaterial
        map={texture}
        side={THREE.BackSide}
      />
    </mesh>
  );
}

export default function TokyoNight() {
  return (
    <>
      <VRButton />
      <Canvas>
        <XR referenceSpace="local-floor">
          <ambientLight intensity={2} />

          <spotLight
            position={[0, 20, 10]}
            angle={0.3}
            penumbra={0.5}
            intensity={1}
            castShadow
          />

          <Panorama />
          <OrbitControls />
        </XR>
      </Canvas>
    </>
  );
}
